import React, { useState, useEffect } from "react"
import axios from "axios"

export const PredictiveGamingContext = React.createContext()

export const Context = ({ gameData, children }) => {
  const [answers, setAnswers] = useState(null)
  const [background, setBackground] = useState("#fff")

  const onSubmit = (userData) => {
    let _formattedAnswers = []
    answers.map((answer) => {
      _formattedAnswers.push({
        node: answer.question,
        nodeValue: answer.answer,
      })
    })
    userData.map((data) => {
      _formattedAnswers.push(data)
    })

    axios({
      method: "POST",
      url: `/.netlify/functions/api`,
      data: _formattedAnswers,
      crossDomain: true,
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onAnswer = (question, answer) => {
    let _q = answers.find((q) => q.question == question)
    _q.answer = answer
    setAnswers([...answers])
  }

  useEffect(() => {
    let _answers = []
    gameData.questions.forEach((question, index) => {
      _answers.push({
        question: question.title.titleEn,
        answer: "",
      })
    })

    setAnswers(_answers)
    // console.log(_answers)
  }, [])
  return (
    <PredictiveGamingContext.Provider
      value={{
        actions: {
          onSubmit,
          onAnswer,
          setBackground,
        },
        gameData,
        background,
        answers,
      }}
    >
      {children}
    </PredictiveGamingContext.Provider>
  )
}
