import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Context as GlobalContextProvider } from "@context"
import { Context as GameContextProvider } from "@context/game"
import { Context as SiteContextProvider } from "@context/siteContext"
import { Context as LanguageContextProvider } from "@context/language"
import { Context as PredictiveGamingContextProvider } from "@context/predictiveGamingContext"
import CSSConfigProvider from "@components/CSSConfigProvider"
import Head from "@components/dom/Head"
import Transition from "@components/dom/transition"
import { Nav } from "@dom"
import { Menu } from "@dom"
import "@jsUtils/onInitThree.js"

// Global Styles
require("./styles/index.scss")

const DefaultLayout = ({ children, path, ...props }) => {
  const siteData = useStaticQuery(
    graphql`
      query {
        timeline: wpcontent {
          pageBy(uri: "timeline") {
            id
            timeline {
              decades {
                year
                chapters {
                  ... on WPGraphQL_TimelineChapter {
                    id
                    timelineChapter {
                      content {
                        assetType
                        asset {
                          id
                          mediaItemUrl
                        }
                        loop
                        keyOutBackground
                        specs {
                          lg {
                            x
                            y
                            z
                            scale
                          }
                          md {
                            x
                            y
                            z
                            scale
                          }
                          sm {
                            x
                            y
                            z
                            scale
                          }
                        }
                        hasContent
                        description {
                          year
                          categoryEn
                          categoryEs
                          copyEn
                          copyEs
                          icon {
                            id
                            mediaItemUrl
                          }
                          position {
                            lg {
                              x
                              y
                            }
                            md {
                              x
                              y
                            }
                            sm {
                              x
                              y
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        gameData: wpcontent {
          pageBy(uri: "Play Predictor") {
            id
            PlayPredictorContent {
              fieldGroupName
              active
              nextGame
              questions {
                ... on WPGraphQL_Page_Playpredictorcontent_Questions_Multiplechoice {
                  fieldGroupName
                  title {
                    hasSponsor
                    headlineEn
                    headlineEs
                    sponsorLogo {
                      mediaItemUrl
                    }
                    titleEn
                    titleEs
                  }
                  background {
                    mediaItemUrl
                  }
                  content {
                    image {
                      mediaItemUrl
                    }
                    titleEn
                    titleEs
                  }
                }
                ... on WPGraphQL_Page_Playpredictorcontent_Questions_Aorb {
                  fieldGroupName
                  title {
                    hasSponsor
                    headlineEn
                    headlineEs
                    sponsorLogo {
                      mediaItemUrl
                    }
                    titleEn
                    titleEs
                  }
                  content {
                    element
                    image {
                      mediaItemUrl
                    }
                    titleEs
                    titleEn
                  }
                }
                ... on WPGraphQL_Page_Playpredictorcontent_Questions_Aorbalt {
                  fieldGroupName
                  title {
                    hasSponsor
                    headlineEn
                    headlineEs
                    sponsorLogo {
                      mediaItemUrl
                    }
                    titleEn
                    titleEs
                  }
                  background {
                    mediaItemUrl
                  }
                  content {
                    titleEs
                    titleEn
                  }
                }
                ... on WPGraphQL_Page_Playpredictorcontent_Questions_Slider {
                  fieldGroupName
                  max
                  min
                  title {
                    hasSponsor
                    headlineEn
                    headlineEs
                    sponsorLogo {
                      mediaItemUrl
                    }
                    titleEn
                    titleEs
                  }
                }
              }
            }
          }
        }
      }
    `,
  )

  const renderTimeline = () => {
    return (
      <GlobalContextProvider timeline={siteData.timeline.pageBy.timeline}>
        <CSSConfigProvider />
        {children}
      </GlobalContextProvider>
    )
  }

  const renderGame = () => {
    return <GameContextProvider>{children}</GameContextProvider>
  }

  const renderPredictiveGaming = () => {
    return (
      <PredictiveGamingContextProvider gameData={siteData.gameData.pageBy.PlayPredictorContent}>
        {children}
      </PredictiveGamingContextProvider>
    )
  }

  const renderDefault = () => {
    return <>{children}</>
  }

  const getLayout = () => {
    switch (props?.location?.pathname) {
      case "/game/":
        return renderGame()
        break
      case "/timeline/":
        return renderTimeline()
        break
      case "/predictive-gaming/":
        return renderPredictiveGaming()
        break
      default:
        return renderTimeline()
        break
    }
  }
  return (
    <SiteContextProvider path={path}>
      <LanguageContextProvider>
        <Head></Head>
        <Transition></Transition>
        <Nav></Nav>
        <Menu></Menu>
        {getLayout()}
      </LanguageContextProvider>
    </SiteContextProvider>
  )
}

export default DefaultLayout
