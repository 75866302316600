import get from "lodash-es/get"

import useLanguageContext from "./useLanguageContext"
import { timeline, game, predictiveGaming } from "@translations"

export default function useT(type = "timeline") {
  const { currentLanguage } = useLanguageContext()

  let data

  switch (type) {
    case "timeline":
      data = timeline
      break
    case "game":
      data = game
      break
    case "predictive-gaming":
      data = predictiveGaming
      break

    default:
      data = timeline
  }

  return (key) => {
    return get(get(data, currentLanguage, "en"), key, key)
  }
}
