// the “zooming” path part lasts up until z = pathLength * maxZ
// i.e. pathLength is the proportion of the scene depth that's devoted to the zoom path
export const scroll = {
  pathLength: 0.75,
  speedFactor: 0.003,
}

export const trackingDistanceRange = [-1, 10]

export const camera = {
  initialDistance: 60,
  focusDistance: 6,
  near: 0.1,
  far: 140,
  progressEase: {
    desktop: 0.1,
    mobile: 0.2,
  },
}

export const chapters = {
  internalDepthSpread: 2,
  distanceBetween: 20,
  nearClipFadeRange: {
    desktop: [1.18, 1.33],
    mobile: [0.75, 0.95],
  },
}

export const trophy = {
  modelHeight: 40,
  distanceFromLastChapter: 120,
  orbitRadius: 60,
  rotationAngle: Math.PI * 2,
}

export const postprocessing = {
  fisheye: {
    scale: 0.75,
    strength: 0.5,
    kube: 1.9,
    k: -0.4,
  },
}

export const colors = {
  background: "#0c0c0c",
  chromaKey: "#02BA49",
  gold: "#D8B97D",
}

export const fog = {
  global: {
    color: colors.background,
    near: camera.far - 30,
    far: camera.far,
  },
  distanceFadePlanes: {
    // this is even used for visibility to boost performances
    near: 13,
    far: 25,
  },
}
