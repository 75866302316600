import React, { useState, useEffect, useRef, useContext } from "react"
import { motion, useTransform } from "framer-motion"
import { animate } from "popmotion"

import { config } from "@data"
import { useGlobalContext, useT, useGlobalAction } from "@hooks"
import useCameraPath from "@webgl/Camera/useCameraPath"

import { SiteContext } from "@context/siteContext"

import MouseScrollIcon from "@static/images/icons/mouse-scroll.svg"
import TouchScrollIcon from "@static/images/icons/touch-scroll.svg"

import { Html } from "@dom"

import { css } from "@jsUtils"
import * as styles from "./LandingContent.module.scss"

export default function LandingContent() {
  const { camera } = config
  const {
    scrollLocked,
    cameraZMotionValue,
    actions: { unlockScroll },
    isAppLoaded,
  } = useGlobalContext()

  const { isAudioActive } = useContext(SiteContext)

  const { setHasUserStarted } = useGlobalAction()
  const soundtrack = useRef()

  const t = useT("timeline")

  useEffect(() => {
    document.scrollingElement.style.overflowY = scrollLocked ? "hidden" : "auto"
  }, [scrollLocked])

  const cameraCurve = useCameraPath()
  const scrollAnimation = useRef(null)

  // Cancel any in-progress scrolling animation when the user scrolls
  useEffect(() => {
    const cancelAnimatedScroll = () => {
      if (scrollAnimation.current) scrollAnimation.current.stop()
    }
    window.addEventListener("wheel", cancelAnimatedScroll)
    window.addEventListener("touchmove", cancelAnimatedScroll)
    return () => {
      window.removeEventListener("wheel", cancelAnimatedScroll)
      window.removeEventListener("touchmove", cancelAnimatedScroll)
    }
  }, [])

  const go = () => {
    if (isAppLoaded) {
      unlockScroll()
      soundtrack.current.play()
      document.body.classList.add("active")

      setTimeout(() => {
        setHasUserStarted(true)
      }, 3000)

      const targetZ = -camera.initialDistance + camera.focusDistance

      // Pick 100 points from the camera curve to test for their proximity to the targetZ
      const numDivisions = 500
      const points = cameraCurve.getSpacedPoints(numDivisions).map(({ x, y, z }, i) => ({
        ...{ x, y, z, i },
        // How far is this point from our target?
        score: Math.abs(z - targetZ),
      }))
      // Find the best one
      const optimal = points.sort((a, b) => a.score - b.score)[0]
      // Set the page scroll such that the “normalized progress” (scroll position / canvas container height)
      // gets us where we want to be
      const scrollProgressDenominator = document.getElementById("canvas-container").offsetHeight
      const targetScrollPosition = (optimal.i / numDivisions) * scrollProgressDenominator

      scrollAnimation.current = animate({
        from: window.scrollY,
        to: targetScrollPosition,
        duration: 3000,
        onUpdate(value) {
          window.scrollTo(0, value)
        },
        onComplete() {
          scrollAnimation.current = null
        },
      })
    }
  }

  const [isTouch, setIsTouch] = useState(false)
  useEffect(() => {
    setIsTouch(window.matchMedia("(pointer: coarse)").matches)
  }, [])
  // Is the user in the range where we can show the scroll indicator?
  const [inScrollIndicatorRange, setInScrollIndicatorRange] = useState(false)
  useEffect(
    () =>
      cameraZMotionValue.onChange((val) => {
        setInScrollIndicatorRange(Math.abs(-camera.initialDistance - val) < 5)
      }),
    [],
  )

  useEffect(() => {
    soundtrack.current.muted = !isAudioActive
  }, [isAudioActive])

  return (
    <>
      <audio src={`/audio/concacaf60.mp3`} ref={soundtrack} loop />
      <motion.button
        type="button"
        className={styles.clickTarget}
        onClick={go}
        style={{
          opacity: useTransform(
            cameraZMotionValue,
            [-camera.focusDistance, -(camera.initialDistance / 4 + camera.focusDistance)],
            [1, 0],
          ),
          pointerEvents: useTransform(cameraZMotionValue, (z) =>
            -z < camera.initialDistance / 4 + camera.focusDistance ? "all" : "none",
          ),
        }}
      >
        <div className={css(styles.button, !isAppLoaded && styles.loading)}>
          <div>
            <motion.div
              style={{
                opacity: 0,
              }}
              animate={{
                opacity: isAppLoaded ? 1 : 0,
                y: isAppLoaded ? 0 : 5,
              }}
              transition={{
                delay: 0.5,
                type: "tween",
              }}
            >
              <Html>{t("landing.start-cta")}</Html>
            </motion.div>
          </div>
          <motion.div
            className={css(styles.loadingMessage)}
            initial={{
              opacity: 0,
              x: "-50%",
              y: "-50%",
            }}
            animate={{
              opacity: isAppLoaded ? 0 : 1,
              y: isAppLoaded ? "calc(-50% -5px)" : "-50%",
            }}
            transition={{
              type: "tween",
              delay: isAppLoaded ? 0 : 1,
            }}
          >
            {t("landing.loading")}
          </motion.div>
        </div>
      </motion.button>

      <motion.div
        className={css(styles.scrollIndicator, !isAppLoaded && styles.loading)}
        style={{ opacity: 0 }}
        animate={{ opacity: inScrollIndicatorRange ? 1 : 0 }}
        transition={{ delay: inScrollIndicatorRange ? 5 : 0 }}
      >
        {isTouch ? <TouchScrollIcon /> : <MouseScrollIcon />}
        <span>Scroll down to experience</span>
      </motion.div>
    </>
  )
}
