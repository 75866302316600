import React, { useMemo, useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { Fog } from "three"

import { config } from "@data"

/**
 * This fog is the global one, used for the trophy.
 * For the planes we have different values = not binded to this fog
 */
export default React.memo(function SceneFog() {
  const { fog } = config
  const { global } = fog
  const { scene } = useThree()

  const sceneFog = useMemo(() => {
    return new Fog(global.color, global.near, global.far)
  }, [])

  useEffect(() => {
    scene.fog = sceneFog
  }, [])

  return null
})
