import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"

import { useGlobalContext, useResponsiveVersion } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

import { RafManager } from "@helpers"

import { css } from "@jsUtils"
import * as styles from "./index.module.scss"

import Content from "./Content"

export default React.memo(function AssetDescription() {
  const ref = useRef()
  const mainTl = useRef()

  const { unprojectedDescriptionPosition, activeChapter, content, chaptersProgress } =
    useGlobalContext()

  const { organizedDescriptions } = content
  const data = organizedDescriptions[activeChapter]

  const position = data?.position
  const responsiveData = useMediaQuery({
    xs: position?.sm,
    ...position,
  })

  const isLeft = responsiveData?.x < 0.5
  const isMobile = useResponsiveVersion("mobile")

  const setMobileTl = () => {
    mainTl.current
      .fromTo(ref.current, { scale: 0.9 }, { duration: 1, scale: 1.1, ease: "none" })
      .fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
      .to(ref.current, { duration: 0.25, opacity: 0 }, 0.75)
      .fromTo(ref.current, { y: -10 }, { duration: 1, y: 10, ease: "none" }, 0)
  }

  const setDesktopTl = () => {
    mainTl.current
      .fromTo(ref.current, { duration: 1, scale: 0.8 }, { scale: 1.2 })
      .fromTo(ref.current, { duration: 0.3, opacity: 0 }, { opacity: 1 }, 0)
      .to(ref.current, { duration: 0.3, opacity: 0 }, 0.7)
  }

  useEffect(() => {
    mainTl.current = gsap.timeline({
      paused: true,
    })

    gsap.set(ref.current, { clearProps: "all" })

    if (isMobile) {
      ref.current.style = ""
      setMobileTl()
    } else {
      setDesktopTl()
    }

    return () => {
      mainTl.current.kill()
    }
  }, [isLeft, isMobile])

  useEffect(
    function onMount() {
      const animate = () => {
        if (activeChapter) {
          if (!isMobile) {
            ref.current.style.top = `${
              unprojectedDescriptionPosition.current.y * window.innerHeight
            }px`
            ref.current.style.left = `${
              unprojectedDescriptionPosition.current.x * window.innerWidth
            }px`
          }

          mainTl.current.progress(chaptersProgress.current[activeChapter])
        }
      }

      RafManager.add(animate)

      return () => {
        RafManager.remove(animate)
      }
    },
    [activeChapter, chaptersProgress, isMobile],
  )

  return (
    <>
      <div
        ref={ref}
        className={css(styles.root, isLeft && styles.isLeft, isMobile && styles.isMobile)}
      >
        <Content data={data} />
      </div>
    </>
  )
})
