import { useContext, useMemo } from "react"
import { ThreeAssetsContext } from "@context/game/threeAssets"
import { useGameMachine } from "@hooks"

export default function useGameAsset(key) {
  const [state] = useGameMachine()
  const isAppLoaded = state.context.isAppLoaded

  const { assets } = useContext(ThreeAssetsContext)

  return useMemo(() => assets.get(key), [isAppLoaded])
}
