import React, { Suspense, useMemo } from "react"

import { useGlobalContext } from "@hooks"

import { Landing, Decades, Dust, Lights, Fog, Trophies } from "@webgl"

export default function Stage() {
  const { content, isAppLoaded } = useGlobalContext()

  const decadesNodes = useMemo(() => {
    return content.decades.map((el, i) => {
      return <Decades key={i} i={i} data={el} />
    })
  }, [])

  return (
    <>
      <Fog />
      <Suspense fallback={null}>
        <Landing />
        {decadesNodes}
      </Suspense>

      <Dust />
      {isAppLoaded && <Trophies />}
      <Lights />
    </>
  )
}
