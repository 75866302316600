import React, { useRef, useEffect, useMemo, useCallback } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { Vector2, Vector3, SplineCurve, MathUtils } from "three"
import { transform } from "framer-motion"

import { useGlobalContext } from "@hooks"
import { config, rafOrder } from "@data"

import DebugSpline from "./DebugSpline"

import useCameraPath from "./useCameraPath"

const V3 = new Vector3()

// Find the Y value of a curve for an input X value by binary search. Assumes the curve is a
// function (i.e. X values increase strictly throughout; one Y value per X value)
// Written mostly by copilot lol
function curveSearch(curve, searchX, minProgress = 0, maxProgress = 1, iterations = 0) {
  const midpoint = (minProgress + maxProgress) / 2
  const { x, y } = curve.getPointAt(midpoint)
  if (Math.abs(x - searchX) < 0.00001 || iterations > 100) {
    return y
  } else if (x > searchX) {
    return curveSearch(curve, searchX, minProgress, midpoint, iterations + 1)
  } else {
    return curveSearch(curve, searchX, midpoint, maxProgress, iterations + 1)
  }
}

export default function Camera() {
  const camera = useRef()
  const destPosition = useRef(new Vector3())

  const { camera: cameraConfig } = config
  const { focusDistance, initialDistance, near, far } = cameraConfig
  const distanceBetweenChapters = config.chapters.distanceBetween

  const direction = useRef(V3.clone())

  const { progress, mousePosition, cameraZ, trophyPosition, isDebugMode, content } =
    useGlobalContext()

  const { set, size } = useThree()

  useEffect(function onMount() {
    camera.current.updateProjectionMatrix()
    set({ camera: camera.current })
  }, [])

  const path = useCameraPath()
  // z positions of all chapters on the timeline
  const chapterPositions = useMemo(
    () =>
      content.decades.flatMap((data) =>
        data.chapters.map((el) => -(el.progressID * distanceBetweenChapters + initialDistance)),
      ),
    [content.decades, distanceBetweenChapters, initialDistance],
  )
  // progress values (on the curve) of all chapters on the timeline
  const progressMeasurementResults = useMemo(() => {
    // Mapping from some z positions to the progress they represent on the path
    const curvePoints = path
      .getSpacedPoints(1000)
      .map(({ z }, i) => ({ z, progress: i / 1001 }))
      .sort(({ z: z1 }, { z: z2 }) => z1 - z2)
    const zs = curvePoints.map(({ z }) => z)
    const progs = curvePoints.map(({ progress }) => progress)
    return [-initialDistance, ...chapterPositions].map((z) => transform(z, zs, progs))
  }, [initialDistance, chapterPositions, path])
  const initialProgress = progressMeasurementResults[0]
  const chapterProgressPositions = useMemo(
    () => progressMeasurementResults.slice(1),
    [progressMeasurementResults],
  )
  const lastChapterProgress = chapterProgressPositions[chapterProgressPositions.length - 1]
  // During the chapters, how much does progress change for each unit change in Z?
  const roughProgressPerZ = useMemo(
    () =>
      chapterProgressPositions
        .map((pos, i, arr) => (pos - arr[0]) / (chapterPositions[i] - chapterPositions[0]))
        // Throw out NaN values
        .filter((ratio) => ratio)
        // Get the average
        .reduce((a, b, i, arr) => a + b / arr.length, 0),
    [chapterPositions, chapterProgressPositions],
  )

  // At what progress value should we go from linear pacing to deliberate pacing? At what progress
  // value should we go back to linear pacing?
  const startPaceProgress = initialProgress - roughProgressPerZ * focusDistance
  const endPaceProgress = lastChapterProgress - roughProgressPerZ * focusDistance

  const preMargin = -roughProgressPerZ * 2
  const postMargin = -roughProgressPerZ * (focusDistance + 2)

  const paceSpline = useMemo(
    () =>
      new SplineCurve([
        new Vector2(startPaceProgress, startPaceProgress),
        ...chapterProgressPositions.flatMap((p, i, { length, ...arr }) => {
          // Build array of spline points around this chapter
          const out = []
          out.push(new Vector2(p, p))
          // When the un-paced progress is after progrses p, we want to have covered less distance than that.
          if (i < length - 1) {
            out.push(new Vector2(MathUtils.lerp(p + postMargin, arr[i + 1], 0.5), p + postMargin))
          }
          return out
        }),
        new Vector2(endPaceProgress, endPaceProgress),
      ]),
    [startPaceProgress, endPaceProgress, chapterProgressPositions, postMargin],
  )

  // Transform “uniform” (i.e. 1:1 with scroll position) progress values in [0, 1] into “paced”
  // progress values in the same range that speed up and slow down around chapter positions.
  const paceProgress = useCallback((uniformProgress) => {
    // No scaling before first chapter or after last chapter
    return uniformProgress
  }, [])

  const time = useRef(0)
  const oldSpeedRef = useRef(progress.current.speed)

  useFrame((state, delta) => {
    time.current += delta
    const linearProgress = progress.current.normalizedValue

    const interpolatedProgress = paceProgress(linearProgress)

    path.getPointAt(interpolatedProgress, destPosition.current)

    cameraZ.current = destPosition.current.z

    // Dampen the parallax effect at the beginning of the scene (the landing page)
    const xyScale = Math.min(
      Math.max((-cameraZ.current + initialDistance * 0.5) / (initialDistance * 1.5), 0),
      1,
    )

    time.current += delta

    const t = time.current
    const { sin } = Math
    const floatPosition = {
      x: 0.015 * sin(t / 10) + -0.015 * sin(t / 4) + -0.03 * sin(t / 5) + -0.01 * sin(t / 1.5),
      y: -0.05 * sin(t / 9) + 0.05 * sin(t / 5) + -0.075 * sin(t / 4) + 0.025 * sin(t),
    }
    const lerpedSpeed = MathUtils.lerp(oldSpeedRef.current, progress.current.speed, 0.01)
    oldSpeedRef.current = lerpedSpeed
    const floatScale =
      // Reduce at start page
      MathUtils.clamp((destPosition.current.z / -focusDistance) * 0.9 + 0.1, 0, 1) *
      // Max ensures we are quick to remove float effect but slow to restore it (we always consider
      // the faster of the “up to date” speed and the “delayed” speed)
      (1 - Math.max(Math.abs(progress.current.speed), Math.abs(lerpedSpeed)) / 0.2)

    destPosition.current.x += mousePosition.current.x * 0.2 * xyScale + floatPosition.x * floatScale
    destPosition.current.y += mousePosition.current.y * 0.2 * xyScale + floatPosition.y * floatScale

    camera.current.position.copy(destPosition.current)
    camera.current.getWorldDirection(direction.current)
    camera.current.position.addScaledVector(direction.current, -focusDistance)
    camera.current.lookAt(0, camera.current.position.y, trophyPosition.z)
  }, rafOrder.threeContent)

  return (
    <>
      <perspectiveCamera
        ref={camera}
        position={[0, 0, 5]}
        near={near}
        far={far}
        fov={60}
        name="MainCamera"
        aspect={[size.width / size.height]}
        onUpdate={(self) => self.updateMatrix()}
      />
      {isDebugMode && <DebugSpline path={path} />}
    </>
  )
}
