import { useContext, useMemo } from "react"
import { ThreeAssetsContext } from "@context/threeAssets"
import { useGlobalContext } from "@hooks"

export default function useThreeAsset(key) {
  const { isAppLoaded } = useGlobalContext()
  const { assets } = useContext(ThreeAssetsContext)

  return useMemo(() => assets.get(key), [isAppLoaded])
}
