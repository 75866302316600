import React, { useState, useEffect, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { MathUtils } from "three"

import { useGlobalContext, useWindowDimensions } from "@hooks"

import { Dust } from "@webgl"
import Single from "./Single"

import { config, rafOrder } from "@data"

const AUTO_ROTATE_SPEED = 0.004

export default React.memo(function Trophies() {
  const trophyContainer = useRef()
  const { trophyPosition, progress, currentTrophy } = useGlobalContext()
  const [current, setCurrent] = useState(0)

  const { trophy } = config
  const { modelHeight, distanceFromLastChapter, orbitRadius } = trophy
  const positionY = -modelHeight * 0.5

  const endContentElement = useRef(document.querySelector("#end-content"))

  const progressRotation = useRef(0)
  const canAutoRotate = useRef(false)
  const autoRotateSpeed = useRef(AUTO_ROTATE_SPEED)

  const [windowWidth, windowHeight] = useWindowDimensions()

  useEffect(() => {
    window.sc = setCurrent
  }, [])

  useFrame(() => {
    // SCROLL PROG
    let p =
      progress.current.smoothValue - endContentElement.current.offsetTop + window.innerHeight * 1.15
    const np = MathUtils.clamp(p / window.innerHeight, 0, 1)

    if (canAutoRotate.current) {
      progressRotation.current -= autoRotateSpeed.current
    }

    if (windowWidth < windowHeight) {
      trophyContainer.current.scale.set(1 - np * 0.2, 1 - np * 0.2, 1 - np * 0.2)
    } else {
      trophyContainer.current.scale.set(1, 1, 1)
    }

    // STOP AFTER A FULL REVOLUTION IF NOT IN THE END CONTENT
    if (np === 0) {
      let mod = progressRotation.current % (Math.PI * 2)
      let invMod = Math.PI * 2 + mod

      if (invMod < autoRotateSpeed.current) {
        canAutoRotate.current = false
        progressRotation.current = -(Math.PI * 2)
        trophyContainer.current.rotation.y = trophyContainer.current.rotation.y % (Math.PI * 2)
      }
    } else {
      canAutoRotate.current = true
    }

    // MAKE IT SMOOTH
    trophyContainer.current.rotation.y = MathUtils.lerp(
      trophyContainer.current.rotation.y,
      progressRotation.current - np * Math.PI * 0.3,
      0.1,
    )
  }, rafOrder.threeContent)

  return (
    <group position={trophyPosition.toArray()} name="trophies-group">
      <Dust
        position={[0, -modelHeight * 0.1, distanceFromLastChapter * 0.35]}
        amount={500}
        spread={[orbitRadius * 2, modelHeight, distanceFromLastChapter]}
      />
      <group ref={trophyContainer} position-y={positionY} name="trophy-group">
        <Single
          isActive={currentTrophy === "gold-cup"}
          assetKey="trophy-gold-cup"
          meshName="Trophy_All"
          shadowScale={0.027}
        />
        <Single
          isActive={currentTrophy === "nations-league"}
          assetKey="trophy-nations-league"
          meshName="All"
          shadowScale={0.26}
        />
        <Single
          isActive={currentTrophy === "champtions-league"}
          assetKey="trophy-youth-league"
          meshName="All_ALL_Flat"
          shadowScale={0.027}
        />
      </group>
    </group>
  )
})
