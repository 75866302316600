import React, { useMemo } from "react"

export default React.memo(function DebugSpline({ path }) {
  const points = useMemo(() => path.getSpacedPoints(800))

  const nodes = points.map((el, i) => {
    return (
      <mesh key={i} position={[el.x, el.y, el.z]} scale={[0.1, 0.1, 0.1]}>
        <boxBufferGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color="lime" />
      </mesh>
    )
  })

  return (
    <>
      <line>
        <bufferGeometry onUpdate={(self) => self.setFromPoints(points)} />
        <lineBasicMaterial color="lime" />
      </line>
      {nodes}
    </>
  )
})
