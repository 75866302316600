import React from "react"
import { Helmet } from "react-helmet"

export default function PageHead() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Concacaf Experience60</title>
      <meta
        name="description"
        content="An interactive experience celebrating the greatest moments of our Concacaf’s first 60 years – offering you a chance to relive them, put yourself in them, and win prizes predicting what will happen next."
      />
      <meta
        name="og:description"
        content="An interactive experience celebrating the greatest moments of our Concacaf’s first 60 years – offering you a chance to relive them, put yourself in them, and win prizes predicting what will happen next."
      />
      <meta name="og:title" content="Concacaf Experience 60" />
      <meta property="og:image" content="http://concacaf60.com/images/og-image.jpg" />
      <meta property="og:image:width" content="1920" />
      <meta property="og:image:height" content="1080" />
      <meta name="twitter:image" content="http://concacaf60.com/images/og-image.jpg" />
      <meta name="twitter:title" content="Concacaf Experience 60" />
      <meta
        name="twitter:image:alt"
        content="An interactive experience celebrating the greatest moments of Concacaf's first 60 years"
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}
