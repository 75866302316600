import React, { useMemo, useState, useEffect } from "react"
import { useMachine } from "@xstate/react"
import { Vector3, CatmullRomCurve3, PlaneGeometry } from "three"
import queryString from "query-string"

import gameMachine from "./machines/game"

const V3 = new Vector3()

export const GameContext = React.createContext()

const createShootingCurve = () => {
  let arr = []
  for (let i = 0; i < 50; i++) {
    arr.push(V3.clone())
  }
  return new CatmullRomCurve3(arr)
}

export const Context = ({ children }) => {
  const [isAudioActive, setIsAudioActive] = useState(true)
  const [isAudioSupported, setIsAudioSupported] = useState(true)
  const audios = useMemo(() => new Map(), [])

  const [_state, _send, gameService] = useMachine(gameMachine, {
    devTools: process.env.NODE_ENV == "development",
  })

  const threeObjs = useMemo(() => ({
    helpers: {
      shootingCurve: createShootingCurve(),
    },
    reusable: {
      planeGeometry: new PlaneGeometry(1, 1, 2, 2),
    },
  }))

  const [isDebugMode, setIsDebugMode] = useState(false)
  const [unprojectedBallPosition, setUprojectedBallPosition] = useState([0.5, 0.5])

  // ON MOUNT
  useEffect(function onMount() {
    // DEBUG
    const parsed = queryString.parse(location.search)
    setIsDebugMode(!!parsed?.debug)

    // Audio
    if (/iPad|iPhone|iPod/.test(navigator.platform)) setIsAudioSupported(false)
  }, [])

  return (
    <GameContext.Provider
      value={{
        actions: {
          setUprojectedBallPosition,
          setIsAudioActive,
        },

        gameService,
        audios,
        threeObjs,

        isDebugMode,
        unprojectedBallPosition,

        isAudioActive,
        isAudioSupported,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}
