/* eslint-disable no-restricted-globals */
import React, { useRef, useEffect, useState, useMemo } from "react"
import { useMotionValue } from "framer-motion"
import queryString from "query-string"
import { Vector3 } from "three"

import { config } from "@data"
import parseData from "./parseData.js"

export const GlobalContext = React.createContext()

const V3 = new Vector3()

export const Context = ({ timeline, children }) => {
  const { chapters, trophy: trophyConfig, camera } = config
  const { distanceBetween } = chapters
  const [isDebugMode, setIsDebugMode] = useState(false)
  const [isAppLoaded, setIsAppLoaded] = useState(false)
  const [isAudioActive, setIsAudioActive] = useState(true)
  const [isEndContentOnTop, setIsEndContentOnTop] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [needsFastScrollOverlay, setNeedsFastScrollOverlay] = useState(false)
  const [fastScrollDestination, setFastScrollDestination] = useState(false)
  const [hasUserStarted, setHasUserStarted] = useState(false)
  const [currentTrophy, setCurrentTrophy] = useState("gold-cup")
  const [isAudioSupported, setIsAudioSupported] = useState(true)

  // PROGRESS & HTML
  const [activeChapter, setActiveChapter] = useState(false)
  const unprojectedDescriptionPosition = useRef({ x: 0, y: 0 })
  const chaptersProgress = useRef([])

  const progress = useRef({
    normalizedValue: 0,
    smoothValue: 0,

    speed: 0,
    absoluteSpeed: 0,
    normalizedSpeed: 0,
  })

  const mousePosition = useRef({
    x: 0,
    y: 0,
  })
  const currentMousePosition = useRef({
    x: 0,
    y: 0,
  })

  const cameraZ = useRef(0)
  const cameraZMotionValue = useMotionValue(0)

  const content = useMemo(() => {
    const d = parseData(timeline)

    for (let i = 0; i < d.itemsAmount; i++) {
      chaptersProgress.current[i] = 0
    }

    return d
  }, [])

  const lastChapterPosition = (content.itemsAmount - 1) * distanceBetween + camera.initialDistance

  const trophyPosition = useMemo(() => {
    return V3.set(0, 0, -(lastChapterPosition + trophyConfig.distanceFromLastChapter)).clone()
  }, [])

  const [scrollLocked, setScrollLocked] = useState(true)
  const unlockScroll = () => setScrollLocked(false)

  // ON MOUNT
  useEffect(function onMount() {
    // DEBUG
    const parsed = queryString.parse(location.search)
    setIsDebugMode(!!parsed?.debug)

    // Audio
    if (/iPad|iPhone|iPod/.test(navigator.platform)) setIsAudioSupported(false)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        actions: {
          setIsAppLoaded,
          unlockScroll,
          setActiveChapter,
          setIsAudioActive,
          setIsEndContentOnTop,
          setLoadingProgress,
          setFastScrollDestination,
          setNeedsFastScrollOverlay,
          setHasUserStarted,
          setCurrentTrophy,
        },

        // DEBUG
        isDebugMode,

        //SCROLL
        scrollLocked,
        progress,
        cameraZ,
        cameraZMotionValue,
        fastScrollDestination,

        // MOUSE
        mousePosition,
        currentMousePosition,

        // CONTENT
        content,

        // USEFUL DATA
        lastChapterPosition,
        trophyPosition,

        // CHAPTERS
        activeChapter,
        chaptersProgress,
        unprojectedDescriptionPosition,

        // APP STATE
        isAppLoaded,
        isAudioActive,
        isEndContentOnTop,
        loadingProgress,
        needsFastScrollOverlay,
        hasUserStarted,

        // MISC
        currentTrophy,
        isAudioSupported,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
