import React from "react"

export default React.memo(function Lights() {
  return (
    <>
      <ambientLight intensity={0.3} />
      <directionalLight intensity={4} position={[0, 2, 2]} />
    </>
  )
})
