import sortBy from "lodash-es/sortBy"

const getDimension = () => {
  if (typeof window !== "undefined") {
    const w = window.innerWidth
    let dim = "sm"
    if (w < 769) {
      dim = "sm"
    } else if (w < 1025) {
      dim = "md"
    } else {
      dim = "lg"
    }

    return dim
  } else {
    return "lg"
  }
}

export default function parseData(data) {
  const newData = { ...data }
  let progress = 0

  const dimension = getDimension()

  const organizedDescriptions = []

  newData.decades.forEach((decade) => {
    decade.progressID = progress
    progress += 1

    decade.chapters.forEach((chapter) => {
      chapter.progressID = progress

      const { content } = chapter.timelineChapter

      // REORDER BASED ON Z DEPTH
      sortBy(content, (v) => v.specs[dimension].z)

      content.forEach((asset) => {
        if (asset.hasContent) organizedDescriptions[progress] = asset.description
        if (asset.hasContent == null) delete asset.description

        asset.specs.sm.scale = asset.specs.sm.scale / 2
        asset.specs.md.scale = asset.specs.md.scale / 2
        asset.specs.lg.scale = asset.specs.lg.scale / 2
      })

      progress += 1
    })
  })

  // ADD USEFUL DATA
  newData.organizedDescriptions = organizedDescriptions
  newData.itemsAmount = progress

  return newData
}
