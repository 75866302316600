import React, { useMemo, useEffect, useRef } from "react"
import { useFrame, useThree } from "@react-three/fiber"

import { useGlobalContext, useGlobalAction, useThreeViewport, useProgress } from "@hooks"

import { Asset, Dust } from "@webgl"

import { rafOrder, config } from "@data"
import DebugViewport from "./DebugViewport"

export default React.memo(function Chapter({ data, positionZ, renderOrder, progressID }) {
  const assetGroup = useRef()
  const { isDebugMode, chaptersProgress } = useGlobalContext()
  const { setActiveChapter } = useGlobalAction()
  const { camera: cameraConfig, fog, chapters } = config
  const { distanceFadePlanes } = fog
  const { internalDepthSpread } = chapters
  const viewport = useThreeViewport(cameraConfig.focusDistance)

  const { camera } = useThree()

  const [progress, isActive] = useProgress(positionZ)

  useEffect(() => {
    setActiveChapter(isActive ? progressID : false)
  }, [isActive])

  useFrame(() => {
    if (isActive) {
      chaptersProgress.current[progressID] = progress.current
    }

    // HANDLING CULLING MANUALLY
    let d = camera.position.z - positionZ

    if (d >= -internalDepthSpread && d < distanceFadePlanes.far + internalDepthSpread) {
      assetGroup.current.visible = true
    } else {
      assetGroup.current.visible = false
    }
  }, rafOrder.threeContent)

  const assetNodes = useMemo(() => {
    return data.map((el, i) => {
      return (
        <Asset
          key={i}
          renderOrder={renderOrder + i}
          assetType={data[i].assetType}
          data={data[i]}
          progress={progress}
          isChapterActive={isActive}
          chapterPositionZ={positionZ}
          progressID={progressID}
          internalID={i}
        />
      )
    })
  }, [isActive])

  return (
    <group position-z={positionZ} name={`chapter-${progressID}`}>
      <group ref={assetGroup} matrixAutoUpdate={false}>
        {assetNodes}
      </group>
      <Dust />

      {isDebugMode && <DebugViewport viewport={viewport} renderOrder={renderOrder} />}
    </group>
  )
})
