import React from "react"

import { useLanguage } from "@hooks"

import { css } from "@jsUtils"
import * as styles from "./index.module.scss"

export default React.memo(function Content({ data }) {
  const isSpanish = useLanguage("es")

  const category = isSpanish ? data?.categoryEs : data?.categoryEn
  const contentBottom = isSpanish ? data?.copyEs : data?.copyEn

  return (
    <>
      <img className={css(styles.cross, styles.isTopLeft)} src="/images/description-cross.svg" />
      <img className={css(styles.cross, styles.isTopRight)} src="/images/description-cross.svg" />
      <img
        className={css(styles.cross, styles.isBottomRight)}
        src="/images/description-cross.svg"
      />
      <img className={css(styles.cross, styles.isBottomLeft)} src="/images/description-cross.svg" />

      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.contentTopYear}> {data?.year} </div>
          <div className={styles.contentTopIcon}>
            <img src={data?.icon?.mediaItemUrl} />
          </div>
          <div className={styles.contentTopCategory}> {category} </div>
        </div>

        <div className={styles.contentBottom}>{contentBottom}</div>
      </div>
    </>
  )
})
