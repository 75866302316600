import { useMediaQuery } from "@helpers/breakpoints"

export default function useResponsiveVersion(key) {
  const version = useMediaQuery({
    xs: "mobile",
    sm: "tablet",
    md: "desktop",
  })

  return key ? key === version : version
}
