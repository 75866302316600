import React from "react"
import { Canvas } from "@react-three/fiber"
import { Context as ThreeAssetsContext } from "@context/threeAssets"

import { Camera, Renderer, Stage } from "@webgl"

import { useContextBridge } from "@hooks"

import { GlobalContext } from "@context"

export default function Cvs() {
  const ContextBridge = useContextBridge(GlobalContext)

  return (
    <Canvas dpr={[1, 2]} gl={{ antialias: true, logarithmicDepthBuffer: true }}>
      <ContextBridge>
        <ThreeAssetsContext>
          <Camera />
          <Renderer />
          <Stage />
        </ThreeAssetsContext>
      </ContextBridge>
    </Canvas>
  )
}
