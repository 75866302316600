import React, { useRef } from "react"
import { useGlobalContext } from "@hooks"
import { useFrame } from "@react-three/fiber"
import SVGMesh from "@webgl/SVGMesh"
import * as THREE from "three"

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix *
                  modelViewMatrix *
                  vec4(position,1.0);
  }
`
const fragmentShader = `
  uniform vec2 mousePos;
  uniform vec2 aspectVector;
  varying vec2 vUv;

  void main() {
    vec2 squareUv = vUv * aspectVector;

    const vec4 gradColorA = vec4(0.82, 0.68, 0.42, 1.);
    const vec4 gradColorB = vec4(0.40, 0.35, 0.25, 1.);

    const float slope = 3.;
    const float scale = 0.85;
    float anglePos = squareUv.x + slope * squareUv.y;
    anglePos += (mousePos.x - mousePos.y) * 0.55;
    vec4 gradColor = mix(gradColorA, gradColorB, sin(anglePos / scale) * 0.5 + 0.5);

    gl_FragColor = gradColor;
  }
`

const shader = {
  vertexShader,
  fragmentShader,
  uniforms: {
    mousePos: { value: [0, 0] },
    aspectVector: { value: [16 / 9, 1] },
  },
}

export default React.forwardRef(function MetalSvg({ src, size, ...props }, ref) {
  // Update mouse position in shader
  const { mousePosition } = useGlobalContext()
  const shaderMaterialRef = useRef()
  useFrame(() => {
    const { x, y } = mousePosition.current
    shaderMaterialRef.current.uniforms.mousePos.value = [x, y]
  })

  return (
    <SVGMesh src={src} size={size} ref={ref} {...props}>
      <shaderMaterial
        args={[shader]}
        ref={shaderMaterialRef}
        side={THREE.DoubleSide}
        uniforms-aspectVector-value={[size[0] / size[1], 1]}
        transparent
      />
    </SVGMesh>
  )
})
