import React from "react"

import { useGlobalAction, useT } from "@hooks"

import { css } from "@jsUtils"
import * as styles from "./index.module.scss"

export default React.memo(function VisitTimeline(props) {
  const { setFastScrollDestination } = useGlobalAction()

  const t = useT("timeline")

  const onClick = () => {
    if (mode == "timeline") {
      setFastScrollDestination(0)
    } else {
    }
  }

  return (
    <div className={css(styles.root)} onClick={onClick}>
      <div className={styles.cta}>
        <span className={styles.arrow}>
          <img src={"/images/icons/arrow--right.svg"} className={styles.initial} />
          <img src={"/images/icons/arrow--right.svg"} className={styles.hover} />
        </span>
        <span className={styles.copy}>{t("end-content.timeline")}</span>
      </div>
      <div className={styles.label}>
        <div className={styles.circle} />
        2021
      </div>
    </div>
  )
})
