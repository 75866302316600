import React, { useContext } from "react"
import { Link as GatsbyLink } from "gatsby"
import { SiteContext } from "@context/siteContext"

const Link = (props) => {
  const { to, children, className } = props
  const { actions } = useContext(SiteContext)
  const handleClick = (e) => {
    e.preventDefault()
    actions.route(to)
  }
  return (
    <GatsbyLink to={to} onClick={handleClick} className={className}>
      {children}
    </GatsbyLink>
  )
}
export default Link
