import { Vector2, Box2, ShapeGeometry, Vector3 } from "three"
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader"

/* eslint-disable class-methods-use-this */

export default class SVGGeometryLoader {
  load({ src, size }, onLoad) {
    const loader = new SVGLoader()
    loader.load(src, ({ paths }) => {
      const shapes = paths.flatMap((p) => p.toShapes())

      // Measure shapes to determine necessary translate/scale
      const bounds = shapes
        .flatMap((s) => s.extractPoints().shape)
        .reduce((accumBox, point) => accumBox.expandByPoint(point), new Box2())
      const center = new Vector2()
      bounds.getCenter(center)
      const translate = center.multiplyScalar(-1)
      const boxSize = new Vector2()
      bounds.getSize(boxSize)
      const scale = new Vector2(size[0], -size[1]).divide(boxSize)

      // Create geometry
      const geometry = new ShapeGeometry(shapes)

      // Update UVs
      const tempVec3 = new Vector3()
      const attPos = geometry.attributes.position
      const attUv = geometry.attributes.uv
      for (let i = 0; i < attPos.count; i++) {
        tempVec3.fromBufferAttribute(attPos, i)
        attUv.setXY(i, (tempVec3.x / size[0]) * scale.x, (tempVec3.y / size[1]) * -scale.y)
        attUv.needsUpdate = true
      }
      geometry.uvsNeedUpdate = true

      // Apply the transformations we calculated
      geometry.translate(...translate.toArray(), 0)
      geometry.scale(...scale.toArray(), 0)

      onLoad(geometry)
    })
  }
}
