import { useEffect } from "react"
import { config } from "@data"

export default function CSSConfigProvider() {
  useEffect(() => {
    Object.entries(config.colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--color-${key}`, value)
      // Add "r, g, b" breakdown for hex colors for use in rgba() expressions
      if (/^#[0-9a-f]{6}$/i.test(value)) {
        const [r, g, b] = [
          [1, 3],
          [3, 5],
          [5, 7],
        ]
          .map(([l, r]) => value.slice(l, r))
          .map((slice) => parseInt(slice, 16))
        document.documentElement.style.setProperty(`--rgb-${key}`, [r, g, b].join(", "))
      }
    })
  }, [])

  return null
}
