import React, { useRef } from "react"
import { Canvas } from "@webgl"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useWindowDimensions, useElementDimensions } from "@hooks/measure"

import * as style from "./index.module.scss"

export default React.memo(function CanvasContainer() {
  const domElement = useRef()
  const { scrollY } = useViewportScroll()
  const [windowWidth, windowHeight] = useWindowDimensions()
  const { height: elementHeight } = useElementDimensions(domElement)

  const transformProgress = useTransform(
    scrollY,
    [elementHeight + windowHeight / 4 - windowHeight, elementHeight],
    [0, 1],
  )
  const transformProgressEased = useTransform(transformProgress, (t) =>
    t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
  ) // easeInOutQuad from https://gist.github.com/gre/1650294

  const isMobile = windowHeight > windowWidth

  return (
    <>
      <div className={style.wrapper}>
        <motion.div
          className={style.transformer}
          style={{
            x: useTransform(transformProgressEased, [0, 1], [0, isMobile ? 0 : windowWidth / 4]),
            z: 1,
            willChange: "transform",
            backfaceVisibility: "hidden",
          }}
        >
          <Canvas style={{ width: windowWidth, height: windowHeight }} />
        </motion.div>
      </div>
      <div className={style.spacer} ref={domElement} id="canvas-container" />
    </>
  )
})
