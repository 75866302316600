import React, { useMemo, useRef, useEffect } from "react"
import { Vector3, Object3D } from "three"
import { useFrame, useThree } from "@react-three/fiber"
import { gsap } from "gsap"

import { useGlobalContext, useResponsiveVersion } from "@hooks"

import { useMediaQuery } from "@helpers/breakpoints"

import { rafOrder, config } from "@data"

const V3 = new Vector3()

export default React.memo(function Description({
  data,
  parentDimensions,
  renderOrder,
  progressID,
}) {
  const line = useRef()
  const mainTl = useRef()

  const {
    isDebugMode,
    activeChapter,
    unprojectedDescriptionPosition,
    chaptersProgress,
    mousePosition,
  } = useGlobalContext()
  const { postprocessing } = config
  const { fisheye } = postprocessing

  const { camera } = useThree()

  const obj = useMemo(() => new Object3D(), [])
  const target = useMemo(() => V3.clone(), [])

  const isMobile = useResponsiveVersion("mobile")
  const isActive = activeChapter === progressID
  const isVisible = isActive && !isMobile

  const { position } = data
  const { x, y } = useMediaQuery({
    xs: position.sm,
    ...position,
  })

  const z = -0.1

  const positionX = (x - 0.5) * parentDimensions[0] || 0
  const positionY = (-y + 0.5) * parentDimensions[1] || 0

  const points = useMemo(() => {
    return [V3.set(positionX, positionY, z).multiplyScalar(0.98).clone(), V3.set(0, 0, z).clone()]
  }, [positionX, positionY])

  const tracking = useMemo(() => points[0].clone(), [points])

  useEffect(() => {
    mainTl.current = gsap.timeline({
      paused: true,
    })

    mainTl.current
      .fromTo(line.current.material, { duration: 0.3, opacity: 0 }, { opacity: 1 })
      .to(line.current.material, { duration: 0.3, opacity: 0 }, 0.7)
  }, [])

  // HANDLE TRACKING
  const updateUnprojectedPosition = (v, camera) => {
    v.project(camera)

    unprojectedDescriptionPosition.current = {
      x: v.x * (0.5 / fisheye.scale) + 0.5,
      y: -v.y * (0.5 / fisheye.scale) + 0.5,
    }
  }

  useFrame(() => {
    if (isActive) {
      if (!isMobile) {
        // UPDATE TRACKING
        tracking.x = points[0].x - mousePosition.current.x * 0.2
        tracking.y = points[0].y - mousePosition.current.y * 0.2

        obj.position.copy(tracking)
        obj.updateMatrix()
        obj.updateWorldMatrix()
        obj.getWorldPosition(target)

        line.current.geometry.attributes.position.setXY(0, tracking.x, tracking.y)
        line.current.geometry.attributes.position.needsUpdate = true

        updateUnprojectedPosition(target, camera)
        mainTl.current.progress(chaptersProgress.current[activeChapter])
      }
    }
  }, rafOrder.threeContent)

  return (
    <>
      <primitive
        object={obj}
        position-x={positionX}
        position-y={positionY}
        position-z={z}
        name={`trackingDescription-${progressID}`}
        matrixAutoUpdate={false}
        onUpdate={(self) => self.updateMatrix()}
        visible={isVisible}
      />
      <line ref={line} renderOrder={renderOrder - 4} visible={isVisible}>
        <bufferGeometry onUpdate={(self) => self.setFromPoints(points)} />
        <lineBasicMaterial color="white" transparent opacity={0} />
      </line>

      {isDebugMode && (
        <mesh position-x={positionX} position-y={positionY}>
          <boxBufferGeometry args={[0.1, 0.1, 0.1]} />
          <meshBasicMaterial color="red" />
        </mesh>
      )}
    </>
  )
})
