import React, { useEffect } from "react"
import { useLoader } from "@react-three/fiber"

import SVGGeometryLoader from "@jsUtils/svgGeometryLoader.js"

export default React.forwardRef(({ src, size = [5, 5], children, ...props }, ref) => {
  const geometry = useLoader(SVGGeometryLoader, { src, size })

  useEffect(() => {
    return () => geometry.dispose()
  })

  return (
    <mesh {...props} ref={ref} geometry={geometry}>
      {children}
    </mesh>
  )
})
