// extracted by mini-css-extract-plugin
export var container = "EndContent-module--container--2CDh7";
export var belowContent = "EndContent-module--below-content--3IseY";
export var aboveContent = "EndContent-module--above-content--2XkG8";
export var trophy = "EndContent-module--trophy--3N8j2";
export var active = "EndContent-module--active--1KaiK";
export var goldCup = "EndContent-module--goldCup--1xZP-";
export var nationsLeague = "EndContent-module--nationsLeague--ONghP";
export var youthLeague = "EndContent-module--youthLeague--3NyXl";
export var siteNav = "EndContent-module--siteNav--Ckn20";
export var backgroundRing = "EndContent-module--background-ring--1vXWD";
export var backgroundText = "EndContent-module--background-text--3vuS1";
export var countdown = "EndContent-module--countdown--1PSO5";
export var spacer = "EndContent-module--spacer--3eeFW";
export var subtitle = "EndContent-module--subtitle--2ntV_";
export var qr = "EndContent-module--qr--33Fdc";
export var arButton = "EndContent-module--arButton--1WK3j";
export var switchTrophy = "EndContent-module--switchTrophy--3QkYB";