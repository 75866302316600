/* eslint-disable no-restricted-globals */
import React, { useRef, useEffect, useMemo, useState } from "react"
import { useThree } from "@react-three/fiber"
import { PlaneBufferGeometry } from "three"

import { useGameMachine } from "@hooks"
import { LoadingManager, TextureLoader, VideoTexture, PMREMGenerator } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

import { threeAssets as threeAssetsList } from "@gameData"

const LM = new LoadingManager()
const TL = new TextureLoader(LM)
const ML = new GLTFLoader(LM)

export const ThreeAssetsContext = React.createContext()

/**
 * Looks like the envMap(s) cannot be created in the callback of the texture loader (fired twice, really weird).
 * So the envmaps are created after everything is loaded.
 */
export const Context = ({ children }) => {
  const [state, send] = useGameMachine()

  const loadImage = (src) => {
    return TL.load(src)
  }

  const loadModel = (id, src, m) => {
    ML.load(src, (res) => {
      m.set(id, res)
    })
  }

  const assets = useMemo(() => {
    // PREVENT SSR ISSUE
    if (typeof window !== "undefined") {
      const m = new Map()

      // STATIC ASSETS
      threeAssetsList.forEach((el) => {
        const { assetType, id, src } = el
        if (assetType === "image") {
          m.set(id, loadImage(src))
        } else if (assetType === "model") {
          loadModel(id, src, m)
        }
      })

      return m
    }
  }, [])

  useEffect(() => {
    const threePromise = new Promise((res) => {
      LM.onLoad = () => {
        res()
      }
    })

    LM.onProgress = (path, item, total) => {
      setTimeout(() => {
        // setThreeProgress(item / total)
      }, 600)
    }

    Promise.all([threePromise]).then(async () => {
      // SEND TO MACHINE
      send("LOADED")
    })
  }, [])

  // useEffect(() => {
  //   const pVideo = videoProgress / videoPromises.current.length
  //   const progress = (pVideo + threeProgress) * 0.5 * 0.9
  //   setLoadingProgress(progress)
  // }, [videoProgress, threeProgress])

  return (
    <ThreeAssetsContext.Provider
      value={{
        assets,
      }}
    >
      {children}
    </ThreeAssetsContext.Provider>
  )
}
