/**
 * @param {String} selector : The selector to query
 * @param {Element} context : The element to search within.
 */
export const q = function q(selector, context) {
  return (context || document).querySelector(selector)
}

/**
 * @param {String} selector : The selector to query
 * @param {Element} context : The element to search within.
 */
export const qAll = function qAll(selector, context) {
  return (context || document).querySelectorAll(selector)
}

/**
 * @param {String} timestampString : i.e. 2018-10-06T00:00-04:00
 */
export const getYearFromTimestamp = function getYearFromTimestamp(timestampString) {
  return timestampString.split("-")[0]
}

export const stripHTML = (html) => {
  if (typeof document === "undefined") {
    return html
  }

  const tmp = document.createElement("DIV")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

/*
     Test for IE11
     */
const windowGlobal = typeof window !== "undefined" && window

export const isIE = function isIE() {
  return (!!windowGlobal.MSInputMethodContext && !!document.documentMode) || !!windowGlobal.msCrypto
}

export const isSimplifiedLayout = function isSimplifiedLayout() {
  return isIPad() || isEdge()
}

export const isIPad = function isIPad() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  }
  return (
    navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
  )
}

export const isEdge = function isEdge() {
  return (
    !!windowGlobal &&
    !!windowGlobal.navigator &&
    windowGlobal.navigator.userAgent.indexOf("Edge") > -1
  )
}

/**
 * isMobile: checks whether the viewport sizes is <= than MOBILE_BREAKPOINT (i.e. 1024)
 * @type {number}
 */
const MOBILE_BREAKPOINT = 1024

export const isMobile = function isMobile() {
  return windowGlobal.innerWidth <= MOBILE_BREAKPOINT || isIE()
}

// Had to generate this function to evaluate the breakpoint specifically for IE....
export const isMobileIE = function isMobile() {
  return (
    windowGlobal.innerWidth <= MOBILE_BREAKPOINT ||
    windowGlobal.navigator.userAgent.indexOf("iPad") > -1
  )
}

const isString = (value) => {
  return Object.prototype.toString.call(value) === "[object String]"
}

export const css = (...args) => {
  let stylesList = []

  args
    .filter((style) => !!style) // remove any falsey values from our styles array and join our style classes.
    .forEach((style) => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style)) // Use recursion to handle nested array of styles.
      } else if (isString(style)) {
        stylesList.push(style) // Only add strings to our results
      }
    })

  return stylesList.join(" ")
}

export const setCookie = function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const deleteCookie = function deleteCookie(cname) {
  setCookie(cname, "", 0)
}

export const getCookie = function getCookie(cname) {
  if (typeof document === "undefined") {
    return ""
  }
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const mapRange = function (value, in_min, in_max, out_min, out_max) {
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

export const formatTwoDigits = (index) => {
  return index < 10 ? `0${index}` : index
}

export const IOSupported = () => {
  if (typeof window !== "undefined" && window.IntersectionObserver) {
    return true
  }
  return false
}

export const arrayDiffs = (array1, array2) => {
  const comparer = (arr) => {
    return (current) => {
      return (
        arr.filter((currArr) => {
          return currArr.id === current.id
        }).length === 0
      )
    }
  }
  const onlyInArray1 = array1.filter(comparer(array2))
  const onlyInArray2 = array2.filter(comparer(array1))

  return [...onlyInArray1, ...onlyInArray2]
}

export const getMousePosition = (e, scrollingContainer) => {
  let x
  let y
  scrollingContainer = document.scrollingElement || scrollingContainer || document.documentElement
  if (e) {
    if (e.touches && e.touches[0]) {
      x = e.touches[0].clientX
      y = e.touches[0].clientY
    } else {
      // FireFox
      x = e.pageX
      y = e.pageY - scrollingContainer.scrollTop
    }
  } else {
    // IE
    x = window.event.x + document.body.scrollLeft - 2
    y = window.event.y + document.body.scrollTop - 2
  }
  return { x, y }
}

export const formatPathname = (p) => {
  return p.slice(-1) !== "/" ? `${p}/` : p
}

export const hashCode = (s) => {
  let h = 0
  const l = s.length
  let i = 0
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0
  return h
}

export const resolveImages = (data) => {
  const images = {}
  data.edges.map((image) => {
    if (image.node) {
      images[image.node.base] = image.node
    }
    return true
  })

  return images
}

export const getFriendlyTimes = (meeting) => {
  const dateFriendly = new Date(meeting.start_time).toLocaleString(["en-US"], {
    month: "numeric",
    day: "numeric",
  })
  const startTime = new Date(meeting.start_time).toLocaleString(["en-US"], {
    hour: "numeric",
    minute: "numeric",
  })
  const endTime = new Date(meeting.end_time).toLocaleString(["en-US"], {
    hour: "numeric",
    minute: "numeric",
  })

  return {
    date: dateFriendly,
    start: startTime,
    end: endTime,
  }
}

export const getQueryVar = (varName) => {
  // Grab and unescape the query string - appending an '&' keeps the RegExp simple
  // for the sake of this example.
  const queryStr = `${unescape(window.location.search)}&`

  // Dynamic replacement RegExp
  const regex = new RegExp(`.*?[&\\?]${varName}=(.*?)&.*`)

  // Apply RegExp to the query string
  const val = queryStr.replace(regex, "$1")

  // If the string is the same, we didn't find a match - return false
  return val == queryStr ? false : val
}

export const getKeyByValue = (object, value) => {
  // console.log(object)
  return Object.keys(object).find((key) => object[key] === value)
}

export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max)
}
