import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
export const SiteContext = React.createContext()

export const Context = ({ children, path }) => {
  const [transition, setTransition] = useState(false)
  const [ready, setReady] = useState(false)
  const [nextPath, setNextPath] = useState("")
  const [isAudioActive, setIsAudioActive] = useState(true)
  const [isAudioSupported, setIsAudioSupported] = useState(true)
  const [background, setBackground] = useState("white")
  const [navOpen, setNavOpen] = useState(false)

  const route = (route) => {
    // console.log(path, route)
    if (route == path) return false
    setReady(false)
    setTransition(true)
    setNextPath(route)
  }

  const loadRoute = () => {
    navigate(nextPath)
    setNavOpen(false)
  }

  useEffect(() => {
    // Audio
    if (/iPad|iPhone|iPod/.test(navigator.platform)) setIsAudioSupported(false)
  }, [])

  return (
    <SiteContext.Provider
      value={{
        actions: {
          setTransition,
          setReady,
          route,
          loadRoute,
          setIsAudioActive,
          setBackground,
          setNavOpen,
        },
        transition,
        ready,
        isAudioActive,
        isAudioSupported,
        background,
        navOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
