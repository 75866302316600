/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react"

import detectLanguage from "./detectLanguage.js"

export const LanguageContext = React.createContext()

export const Context = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("en")

  // ON MOUNT
  useEffect(function onMount() {
    // LANGUAGE
    setCurrentLanguage(detectLanguage())
  }, [])

  return (
    <LanguageContext.Provider
      value={{
        actions: {
          setCurrentLanguage,
        },
        currentLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
