/* eslint-disable prefer-destructuring */
import { useEffect, useState, useRef, useMemo } from "react"
import { LinearInterpolant } from "three"
import { useFrame } from "@react-three/fiber"

import { useGlobalContext, useThreeAsset } from "@hooks"

import { rafOrder } from "@data"

import { clamp } from "@jsUtils"

export default function useVideoTexture(id, isActive, progress, isPlayActive) {
  const { isAppLoaded, isAudioActive, isAudioSupported } = useGlobalContext()

  const texture = useThreeAsset(id)
  const [ratio, setRatio] = useState()
  const video = useRef(undefined)
  const isFirstFrameRendered = useRef(false)

  const volumeInterpolant = useMemo(() => {
    return new LinearInterpolant(
      new Float32Array([0.0, 0.4, 0.95, 1.0]),
      new Float32Array([0.0, 1.0, 1.0, 0.0]),
      1,
      new Float32Array(1),
    )
  }, [])

  const playVideo = () => {
    try {
      video.current.play().catch((e) => {
        console.error("Couldn't play video. Error:")
        console.error(e)
      })
    } catch {}
  }

  const pauseVideo = () => {
    video.current.pause()
  }

  const renderFirstFrame = () => {
    //   CREATE THUMBNAIL OTHERWISE IS NOT VISIBLE
    video.current.muted = true
    playVideo()

    setTimeout(() => {
      pauseVideo()
      video.current.muted = isAudioSupported ? !isAudioActive : false
      isFirstFrameRendered.current = true
      video.current.volume = 0
    }, 1000)
  }

  useEffect(() => {
    if (texture && isAppLoaded) {
      video.current = texture.image
      setRatio(video.current.clientWidth / video.current.clientHeight)

      renderFirstFrame()
    }
  }, [texture, isAppLoaded])

  useEffect(() => {
    if (isAppLoaded) {
      if (isPlayActive) {
        playVideo()
      } else {
        pauseVideo()
      }
    }
  }, [isPlayActive, isAppLoaded])

  useEffect(() => {
    if (isAppLoaded && isFirstFrameRendered.current) {
      video.current.muted = isAudioSupported ? !isAudioActive : false
    }
  }, [isAudioActive, isAppLoaded])

  useFrame(() => {
    if (isActive) {
      let _volume =
        progress.current < 0.5
          ? clamp(progress.current * 2, 0, 1)
          : clamp(1 + (1 - progress.current * 2), 0, 1)
      if (video.current) video.current.volume = _volume
    }
  }, rafOrder.threeContent)

  return [texture, ratio]
}
