import React, { useMemo, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { Float32BufferAttribute, MathUtils, AdditiveBlending } from "three"

import { useGlobalContext } from "@hooks"

import vertex from "./shaders/index.vert"
import fragment from "./shaders/index.frag"

export default React.memo(function Dust({
  position = [0, 0, 0],
  amount = 50,
  spread = [50, 50, 20],
}) {
  const { progress } = useGlobalContext()
  const time = useRef(0)

  const positions = useMemo(() => {
    const arr = []
    for (let i = 0; i < amount; i++) {
      arr.push(MathUtils.randFloatSpread(spread[0]))
      arr.push(MathUtils.randFloatSpread(spread[1]))
      arr.push(MathUtils.randFloatSpread(spread[2]))
    }

    return new Float32BufferAttribute(arr, 3)
  }, [])

  const scale = useMemo(() => {
    const arr = []
    for (let i = 0; i < amount; i++) {
      arr.push(MathUtils.randFloat(60, 140))
    }

    return new Float32BufferAttribute(arr, 1)
  }, [])

  const delay = useMemo(() => {
    const arr = []
    for (let i = 0; i < amount; i++) {
      arr.push(MathUtils.randFloat(0.0, 2.0))
    }

    return new Float32BufferAttribute(arr, 1)
  }, [])

  const speed = useMemo(() => {
    const arr = []
    for (let i = 0; i < amount; i++) {
      arr.push(MathUtils.randFloat(0.8, 2.0))
    }

    return new Float32BufferAttribute(arr, 1)
  }, [])

  const args = useMemo(
    () => ({
      uniforms: {
        uTime: { value: 0 },
        uSpeed: { value: 0 },
        distanceFadeNear: { value: 50 },
        distanceFadeFar: { value: 80 },
        fadeAmount: { value: 0 }, // 1 is invisible
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    }),
    [],
  )

  const endContentElement = useRef(document.querySelector("#end-content"))

  useFrame(() => {
    time.current += 0.0004 + progress.current.absoluteSpeed * 0.007
    args.uniforms.uTime.value = time.current
    args.uniforms.uSpeed.value = progress.current.absoluteSpeed
    // Fade progress
    let fadePos =
      progress.current.smoothValue - endContentElement.current.offsetTop + window.innerHeight * 2
    const clampedFadeProg = MathUtils.clamp(fadePos / (window.innerHeight / 2), 0, 1)
    args.uniforms.fadeAmount.value = clampedFadeProg

    Object.assign(window, { fadePos, clampedFadeProg })
  })

  return (
    <points frustumCulled={false} position={position}>
      <bufferGeometry
        onUpdate={(self) => {
          self.setAttribute("position", positions)
          self.setAttribute("aScale", scale)
          self.setAttribute("aDelay", delay)
          self.setAttribute("aSpeed", speed)
        }}
      />
      <shaderMaterial args={[args]} transparent depthWrite={false} blending={AdditiveBlending} />
    </points>
  )
})
