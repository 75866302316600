import * as styles from "./index.module.scss"
import { useLanguageContext } from "@hooks"

import React from "react"

export default function LanguageSwitch() {
  const {
    currentLanguage,
    actions: { setCurrentLanguage },
  } = useLanguageContext()

  return (
    <label className={styles.languageSelect}>
      <select value={currentLanguage} onChange={(e) => setCurrentLanguage(e.target.value)}>
        <option value="es">Spanish</option>
        <option value="en">English</option>
      </select>
      <span>&#9662;</span>
    </label>
  )
}
