import { useEffect, useState } from "react"
import { useThree } from "@react-three/fiber"
import { MathUtils } from "three"

import { config } from "@data"

export default function useThreeViewport(distance = config.camera.focusDistance) {
  const [sizes, setSizes] = useState({ width: 16, height: 9 })
  const { camera, size } = useThree()

  useEffect(() => {
    const vFOV = MathUtils.degToRad(camera.fov)
    const width = (distance * camera.aspect) / (2 * Math.tan(vFOV / 2))
    const height = width / camera.aspect

    setSizes({ width, height })
  }, [size])

  return sizes
}
