import React from "react"

import Image from "../Image"
import Video from "../Video"

const components = {
  video: Video,
  image: Image,
}

export default React.memo(function Asset({ assetType = "image", ...props }) {
  const Component = components[assetType] || <></>

  return <Component {...props} />
})
