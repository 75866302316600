import React, { useState, useEffect, useContext } from "react"
import * as styles from "./index.module.scss"

import { motion, useTransform, useSpring } from "framer-motion"
import { useGlobalContext, useGlobalAction } from "@hooks"
import { SiteContext } from "@context/siteContext"
import { css } from "@jsUtils"
import { config } from "@data"

import SkipButton from "./SkipButton"
import LanguageSwitch from "./LanguageSwitch"
import Link from "@components/dom/Link"
import Menu from "./menu"

export default React.memo(function Header(props) {
  const { isAudioActive, isEndContentOnTop, isAudioSupported, setIsAudioActive, mode } = props
  const { background } = useContext(SiteContext)

  const { colors } = config

  const icon = isAudioActive ? "/images/icons/audio--off.svg" : "/images/icons/audio--on.svg"

  const toggleAudio = () => {
    setIsAudioActive(isAudioSupported ? !isAudioActive : false)
  }

  const spring = useSpring(0, { duration: 500 })

  if (mode == "timeline") spring.set(isEndContentOnTop ? 1 : 0)
  let color = mode == "timeline" ? useTransform(spring, [0, 1], ["#ffffff", "#000000"]) : "#ffffff"
  if (mode == "index") color = background == "white" ? "#000" : "#fff"
  if (mode == "predictive-gaming") color = background == "white" ? "#000" : "#fff"

  const opacity = useTransform(spring, [0, 0.8, 1], [1, 0, 0])
  const visibility = useTransform(spring, [0, 0.8, 1], ["visible", "visible", "hidden"])

  return (
    <>
      <div className={styles.logo}>
        <Link to={"/"} className={styles.concacaf}>
          <span className={styles.conc}>Concacaf</span>{" "}
        </Link>
      </div>
      <span className={styles.number}>60</span>

      <div className={styles.bottom}>
        {/* <LanguageSwitch /> */}
        {isAudioActive != null && (
          // <motion.div className={styles.containerCenter} style={{ opacity, visibility }}>
          //   <img onClick={toggleAudio} src={icon} />
          // </motion.div>
          <img onClick={toggleAudio} src={icon} />
        )}
      </div>

      <div className={styles.landscape}>Rotate your phone.</div>
    </>
  )
})
