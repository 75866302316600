// Absolutely worth a try > https://xstate.js.org/
import { createMachine, spawn, assign, send, actions } from "xstate"
import { inspect } from "@xstate/inspect"

import { levels } from "@gameData"

// LIVE DEBUG
try {
  if (process.env.NODE_ENV == "development") {
    inspect({
      // https://statecharts.io/inspect
      iframe: true,
    })
  }
  // eslint-disable-next-line no-empty
} catch (e) {}
// END DEBUG

import levelMachine from "./level"

const { choose } = actions

export default createMachine(
  {
    id: "game",
    initial: "loading",
    context: {
      isAppLoaded: false,
      levelMachineRef: null, // assign the sub-machine dynamically
      currentLevel: 0,
      levels: levels,
    },
    entry: assign({
      levelMachineRef: () => spawn(levelMachine, "level"),
    }),

    states: {
      loading: {
        on: {
          LOADED: {
            target: "loaded",
            actions: assign({
              isAppLoaded: true,
            }),
          },
        },
      },
      loaded: {
        after: { 10: { target: "selecting" } },
      },
      opening: {
        initial: "animating",
        states: {
          animating: {
            on: {
              DONE: "#game.playing",
            },
          },
        },
      },
      selecting: {
        on: {
          START_PLAYING: [
            {
              target: "opening",
              cond: "needsOnboarding",
            },
            {
              target: "playing",
            },
          ],
        },
      },
      playing: {
        entry: "onStartPlaying",
        on: {
          LEVEL_SUCCESS: [
            {
              target: "selecting",
              cond: "canPlayNextLevel",
              actions: ["nextLevel"],
            },
            {
              target: "gameWon",
            },
          ],
          QUIT: {
            target: "selecting",
            actions: "exitLevel",
          },
        },
      },
      gameWon: {},
    },
    on: {
      GAME_WON: "gameWon",
    },
  },
  {
    actions: {
      onStartPlaying: choose([
        {
          cond: "needsOnboarding",
          target: "loading",
          actions: "entryOnboarding",
        },
        {
          actions: "entryLevel",
        },
      ]),
      entryLevel: send("ENTRY_LEVEL", { to: "level" }),
      entryOnboarding: send("ENTRY_ONBOARDING", { to: "level" }),
      exitLevel: send("EXIT_LEVEL", { to: "level" }),
      nextLevel: assign({
        currentLevel: (ctx) => ctx.currentLevel + 1,
        levels: (ctx) => {
          ctx.levels[ctx.currentLevel].status = "completed"
          return ctx.levels
        },
      }),
    },
    guards: {
      needsOnboarding: (ctx) => ctx.currentLevel === 0,
      canPlayNextLevel: (ctx) => ctx.currentLevel < levels.length - 1,
    },
  },
)
