import { ShaderChunk } from "three"

// EMULATE FOG
ShaderChunk.distanceFade_pars_vertex = `
    varying float fadeDepth;
`

ShaderChunk.distanceFade_vertex = `
    fadeDepth = - mvPosition.z;
`

ShaderChunk.distanceFade_pars_fragment = `
    varying float fadeDepth;
    uniform float distanceFadeNear;
    uniform float distanceFadeFar;
`

ShaderChunk.distanceFade_fragment = `
    float fadeFactor = 1.0 - smoothstep( distanceFadeNear, distanceFadeFar, fadeDepth );
    gl_FragColor.a *= fadeFactor;
`

// PREVENT NEAR CLIP
ShaderChunk.nearClipFade_pars_vertex = `
    varying float dist;
    uniform vec2 uNearClipFadeRange;
`

ShaderChunk.nearClipFade_vertex = `
    vec4 mv  = modelMatrix * vec4(newPos, 1.0);

    dist = abs(distance(cameraPosition, mv.xyz));
    dist = smoothstep(uNearClipFadeRange[0], uNearClipFadeRange[1], dist);
`

ShaderChunk.nearClipFade_pars_fragment = `
    varying float dist;
`

ShaderChunk.nearClipFade_fragment = `
    gl_FragColor.a *= dist;
`
