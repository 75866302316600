import React, { useRef } from "react"
import { Ring, Sixty, MetalSvg } from "@webgl/atoms/landing"
import { Dust } from "@webgl"

import { useGlobalContext } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

import { config } from "@data"

export default function LandingScene() {
  const ring = useRef()
  const { content } = useGlobalContext()

  const renderOrder = content.itemsAmount + 1

  const { camera } = config

  const { scale, radius } = useMediaQuery({
    xs: { scale: 0.55, radius: 3.2 },
    lg: { scale: 1, radius: 3.6 },
  })

  return (
    <group matrixAutoUpdate={false} onUpdate={(self) => self.updateMatrix()}>
      <Dust
        position={[0, 0, -camera.initialDistance * 0.5]}
        spread={[50, 50, camera.initialDistance]}
      />

      <group
        scale={[scale, scale, scale]}
        matrixAutoUpdate={false}
        onUpdate={(self) => self.updateMatrix()}
      >
        <Ring renderOrder={renderOrder} position-z={-5} ref={ring} radius={radius} />
        <MetalSvg
          renderOrder={renderOrder + 1}
          src="/images/webgl/landing/concacaf.svg"
          size={[198 / 125, 28 / 125]}
          position-y={1.2}
          position-z={-1.5}
        />
        <MetalSvg
          renderOrder={renderOrder + 1}
          src="/images/webgl/landing/years.svg"
          size={[109 / 125, 28 / 125]}
          position-y={-1.2}
          position-z={-1.5}
        />
        <Sixty renderOrder={renderOrder + 2} />
      </group>
    </group>
  )
}
