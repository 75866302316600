import split from "lodash-es/split"

export default function detectLanguage() {
  function supportedLang(lang) {
    const supportedLanguages = ["en", "es"]

    return supportedLanguages.includes(lang) ? lang : null
  }

  let webStringLang = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage
  webStringLang = split(webStringLang, "-")[0]

  return supportedLang(webStringLang) || "en"
}
