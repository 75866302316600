import React, { useEffect, useMemo, useRef } from "react"
import { RenderPass, EffectComposer, SavePass, ShaderPass } from "postprocessing"

import { useFrame, useThree, extend } from "@react-three/fiber"
import { WebGLRenderTarget, ShaderMaterial, TextureLoader, RepeatWrapping, MathUtils } from "three"

import { useGlobalContext } from "@hooks"

import GrainTexture from "@assets/webgl/grain.jpg"

import { rafOrder, config } from "@data"
import { RafManager } from "@helpers"

import blendPassVertex from "./shaders/blendPass/index.vert"
import blendPassFragment from "./shaders/blendPass/index.frag"

import fisheyePassVertex from "./shaders/fisheyePass/index.vert"
import fisheyePassFragment from "./shaders/fisheyePass/index.frag"

extend({ EffectComposer })

const TL = new TextureLoader()

/**
 * TODO:
 * CONVERT PASSES TO EFFECTS FOR BETTER PERFORMANCES WHEN APPROVED (?)
 */

export default function Renderer() {
  const { progress } = useGlobalContext()
  const { postprocessing } = config
  const { fisheye: fisheyeConfig } = postprocessing

  const composer = useRef()
  const progressive = useRef(0)

  const { scene, camera, gl, size } = useThree()

  const rtt = useMemo(() => {
    return new WebGLRenderTarget(1, 1)
  }, [])

  const grainTexture = useMemo(() => {
    return TL.load(GrainTexture, (self) => {
      self.wrapS = RepeatWrapping
      self.wrapT = RepeatWrapping
    })
  }, [])

  const renderPass = useMemo(() => {
    const r = new RenderPass(scene, camera)
    return r
  }, [scene, camera])

  const savePass = useMemo(() => {
    const s = new SavePass(rtt, false)

    return s
  }, [])

  const blendPass = useMemo(() => {
    const s = new ShaderPass(
      new ShaderMaterial({
        vertexShader: blendPassVertex,
        fragmentShader: blendPassFragment,
        uniforms: {
          tDiffuse: { value: null },
          tSave: { value: savePass.texture },
          uSpeed: { value: 0 },
          uGlobalProgressFactor: { value: 0 },
        },
      }),
      "tDiffuse",
    )

    return s
  }, [])

  const fisheyePass = useMemo(() => {
    const s = new ShaderPass(
      new ShaderMaterial({
        vertexShader: fisheyePassVertex,
        fragmentShader: fisheyePassFragment,
        uniforms: {
          tDiffuse: { value: null },
          uSpeed: { value: 0 },
          tGrain: { value: grainTexture },
          uGrainFactor: { value: 1 },
          uKube: { value: fisheyeConfig.kube },
          uK: { value: fisheyeConfig.k },
          uStrength: { value: fisheyeConfig.strength },
          uScale: { value: fisheyeConfig.scale },
          uGlobalProgressFactor: { value: 0 },
        },
      }),
      "tDiffuse",
    )

    s.renderToScreen = true

    return s
  }, [])

  useEffect(() => {
    const dpr = gl.getPixelRatio()
    const w = size.width * dpr
    const h = size.height * dpr
    rtt.setSize(w, h)

    composer.current.setSize(size.width, size.height)
  }, [size])

  // UPDATE SCROLL & RAF
  useFrame(function of() {
    RafManager.call()
  }, rafOrder.scroll)

  // RENDER EVERYTHING
  useFrame(function of({ gl }, delta) {
    gl.clear()

    progressive.current += 1

    // UPDATE SPEEDS
    blendPass.screen.material.uniforms.uSpeed.value = progress.current.normalizedSpeed
    fisheyePass.screen.material.uniforms.uSpeed.value = MathUtils.lerp(
      progress.current.speed,
      fisheyePass.screen.material.uniforms.uSpeed.value,
      0.99,
    )

    // UPDATE PROGRESS
    blendPass.screen.material.uniforms.uGlobalProgressFactor.value =
      progress.current.normalizedValue
    fisheyePass.screen.material.uniforms.uGlobalProgressFactor.value =
      progress.current.normalizedValue

    if (progressive.current % 10 === 0) {
      fisheyePass.screen.material.uniforms.uGrainFactor.value *= -1
    }

    composer.current.render(delta)
  }, rafOrder.render)

  return (
    <effectComposer
      ref={composer}
      args={[gl]}
      passes={[renderPass, blendPass, savePass, fisheyePass]}
    />
  )
}
