import React, { useState, useEffect, useRef, useMemo } from "react"
import { motion, useTransform, useSpring } from "framer-motion"
import { useGlobalContext, useElementDimensions } from "@hooks"
import { useTransformMulti } from "@hooks/motion"
import { config } from "@data"

import { css } from "@jsUtils"
import * as styles from "./Timeline.module.scss"

export default function Timeline({ horizontal }) {
  const { content, trophyPosition } = useGlobalContext()
  const { camera, trophy, chapters } = config
  const { distanceBetween } = chapters
  // Find each year in the data and pair it up with a Z position
  const zYears = useMemo(() => {
    const data = []
    content.decades.forEach((decade) => {
      data.push({
        year: parseInt(decade.year, 10),
        z: -(decade.progressID * distanceBetween + camera.initialDistance),
      })
      decade.chapters.forEach((chapter) => {
        // check whether any of the content items have a description with a year
        const year = parseInt(
          chapter?.timelineChapter?.content?.find?.((c) => c.description?.year)?.description?.year,
          10,
        )
        if (year) {
          const z = -(chapter.progressID * distanceBetween + camera.initialDistance)
          // stay on the same year a bit before and a bit after the chapter
          data.push({ year, z: z + 2 })
          data.push({ year, z: z - camera.focusDistance })
        }
      })
    })
    data.sort((a, b) => b.z - a.z) // just in case
    return data
  }, [content.decades])

  const years = zYears.map(({ year }) => year)
  const startDecade = Math.floor(Math.min(...years) / 10) * 10
  const endDecade = Math.floor(Math.max(...years) / 10) * 10
  const numDecades = (endDecade - startDecade) / 10 + 1
  const decades = new Array(numDecades).fill(null).map((_, i) => startDecade + i * 10)

  const { cameraZMotionValue: cameraPos } = useGlobalContext()

  // This is a decimal value - 1963.5 means the timeline should be positioned halfway between 1963
  // and 1964
  const currentYearPos = useTransform(
    cameraPos,
    zYears.map(({ z }) => z),
    years,
  )
  // currentYear is the integer year we're on and only changes (and triggers component render) when
  // when we scroll past the end of a year
  const [currentYear, setCurrentYear] = useState(Math.min(...years))
  useEffect(() => {
    setCurrentYear(Math.round(currentYearPos.get()))
    return currentYearPos.onChange(() => {
      const y = Math.round(currentYearPos.get())
      if (currentYear !== y) setCurrentYear(y)
    })
  }, [currentYear, currentYearPos])
  // Determine transform by measuring the width of a decade and multiplying that by how many decades
  // have passed
  const decadeRef = useRef()
  const { height: decadeSize } = useElementDimensions(decadeRef)
  const offset = useTransform(
    currentYearPos,
    (y) => ((y - Math.min(...years)) / 10) * decadeSize * -1,
  )

  const timelineFadeIn = useTransform(
    cameraPos,
    [-camera.initialDistance / 2, -camera.initialDistance * (15 / 16)],
    [0, 1],
  )
  const timelineFadeOut = useSpring(
    useTransform(
      cameraPos,
      [trophyPosition.z + trophy.orbitRadius * 1.1, trophyPosition.z + trophy.orbitRadius],
      [1, 0],
    ),
  )
  const opacity = useTransformMulti([timelineFadeIn, timelineFadeOut], (a, b) => a * b)

  return (
    <motion.aside
      className={css(styles.base, horizontal && styles.horizontal)}
      style={{
        opacity,
        pointerEvents: useTransform(opacity, (op) => (op > 0.1 ? "all" : "none")),
      }}
    >
      <div className={styles.currentYear}>
        <span>{currentYear}</span>
      </div>
      <div className={styles.mask}>
        <motion.ul className={styles.scrollingEl} style={{ y: offset }}>
          {decades.map((decadeYear, i) => (
            <li
              key={decadeYear}
              data-decade={decadeYear}
              className={styles.decade}
              // only measure the first one
              {...(i === 0 && { ref: decadeRef })}
              // The last one isn't as long
              {...(i === decades.length - 1 && {
                style: { height: `${decadeSize * ((Math.max(...years) - endDecade) / 10)}px` },
              })}
            >
              <div className={css(styles.yearLabel)}>{decadeYear}</div>
            </li>
          ))}
        </motion.ul>
      </div>
    </motion.aside>
  )
}
