import { useActor as useActorNative, useService } from "@xstate/react"

import useGameContext from "./useGameContext"

export default function useActor(actor) {
  const gameService = useGameContext()?.gameService
  const [state] = useService(gameService)

  return useActorNative(state.context[actor])
}
