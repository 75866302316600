// Absolutely worth a try > https://xstate.js.org/
import { createMachine, sendParent, assign } from "xstate"

const detectShot = (context) => {
  const { aimPosition, targetPosition } = context

  let xdiff = Math.abs(aimPosition[0] - targetPosition[0])
  let ydiff = Math.abs(aimPosition[1] - targetPosition[1])
  let isSuccess = xdiff < 0.5 && ydiff < 0.5

  return isSuccess
}

/**
 * MAYBE IT WORTH TO USE SUB STATES TO HELP COMPONENTS STRUCTURE like playing > [aiming, shooting, shot] ?
 * >>> REFACTOR ON NEEDS
 */

export default createMachine(
  {
    id: "level",
    initial: "disable",
    context: {
      current: 0,
      aimPosition: [0, 0],
      normalizedAimPosition: [0, 0],
      targetPosition: [0, 0],
      secondsCountdown: 4,
    },
    states: {
      disable: {},
      intro: {
        initial: "moment",
        states: {
          onboarding: {
            on: {
              START: "#level.playing",
            },
          },
          moment: {
            on: {
              START: "#level.playing",
            },
          },
        },
      },
      playing: {
        initial: "initialCountdown",
        states: {
          initialCountdown: {
            entry: "resetCountdown",
            on: {
              DONE: "idle",
              TICK: {
                actions: "updateCountdown",
              },
            },
          },
          idle: {
            on: {
              AIMING: "aiming",
            },
          },
          aiming: {
            on: {
              SHOOTING: "shooting",
            },
          },
          shooting: {
            on: {
              SHOT: "shot",
            },
          },
          shot: {
            always: [
              { target: "#level.outro.success", cond: detectShot },
              { target: "#level.outro.fail" },
            ],
          },
        },
        on: {
          TIME_OUT: "outro.fail",
        },
      },
      outro: {
        initial: "celebrating",
        states: {
          success: {
            always: "celebrating",
          },
          celebrating: {
            on: {
              DONE: "leavingCelebration",
            },
          },
          leavingCelebration: {
            on: {
              DONE: {
                target: "#level.disable",
                actions: sendParent("LEVEL_SUCCESS", { delay: 10 }),
              },
            },
          },
          fail: {
            on: {
              QUIT: {
                actions: sendParent("QUIT"),
              },
              RESTART: "#level.playing",
            },
          },
        },
      },
    },
    on: {
      ENTRY_ONBOARDING: "intro.onboarding",
      ENTRY_LEVEL: "intro.moment",
      EXIT_LEVEL: "disable",
    },
  },
  {
    actions: {
      resetCountdown: assign({
        secondsCountdown: 4,
      }),
      updateCountdown: assign({
        secondsCountdown: (ctx) => ctx.secondsCountdown - 1,
      }),
    },
  },
)
