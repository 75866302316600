import remove from "lodash-es/remove"

class RafManager {
  constructor() {
    this._rafs = []
  }

  add(cb) {
    this._rafs.push(cb)
  }

  remove(cb) {
    remove(this._rafs, (el) => {
      return el === cb
    })
  }

  call() {
    this._rafs.forEach((cb) => {
      cb()
    })
  }
}

const rafManager = new RafManager()

export default rafManager
