import React, { useEffect, useRef } from "react"
import { BackSide, MathUtils } from "three"
import { gsap } from "gsap"

import { useGlobalContext } from "@hooks"

export default React.memo(function Shape({ geometry, angle, radius, progressID, step, color }) {
  const { loadingProgress } = useGlobalContext()
  const ref = useRef()
  const material = useRef()
  const opacity = 0.25

  useEffect(function om() {
    material.current.toneMapped = false
  }, [])

  useEffect(() => {
    const p = MathUtils.smoothstep(loadingProgress, progressID - step, step)
    const duration = 1

    gsap.to(material.current, {
      duration,
      opacity: opacity * p,
      ease: "Power2.easeIn",
    })

    gsap.to(ref.current.scale, {
      duration,
      x: p,
      y: p,
      z: p,
      ease: "Power2.easeInOut",
    })
  }, [loadingProgress])

  return (
    <group rotation-z={angle} matrixAutoUpdate={false} onUpdate={(self) => self.updateMatrix()}>
      <mesh ref={ref} geometry={geometry} position-y={radius} scale={[0, 0, 0]}>
        <meshBasicMaterial
          ref={material}
          side={BackSide}
          opacity={0}
          transparent
          onUpdate={(self) => (self.color = color)}
          depthTest={false}
          depthWrite={false}
        />
      </mesh>
    </group>
  )
})
