/* eslint-disable no-restricted-globals */
import React, { useRef, useEffect, useMemo, useState } from "react"
import { useThree } from "@react-three/fiber"
import { PlaneBufferGeometry } from "three"

import { useGlobalContext } from "@hooks"
import { LoadingManager, TextureLoader, VideoTexture, PMREMGenerator } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

import { threeAssets as threeAssetsList } from "@data"

const LM = new LoadingManager()
const TL = new TextureLoader(LM)
const ML = new GLTFLoader(LM)

export const ThreeAssetsContext = React.createContext()

/**
 * Looks like the envMap(s) cannot be created in the callback of the texture loader (fired twice, really weird).
 * So the envmaps are created after everything is loaded.
 */
export const Context = ({ children }) => {
  const { content, actions } = useGlobalContext()
  const { setIsAppLoaded, setLoadingProgress } = actions
  const videoPromises = useRef([])
  const envMapsIDs = useRef([])
  const [videoProgress, setVideoProgress] = useState(0)
  const [threeProgress, setThreeProgress] = useState(0)
  const reusableResources = useMemo(
    () => ({
      geometries: {
        plane: new PlaneBufferGeometry(1, 1, 20, 20),
      },
    }),
    [],
  )

  const { gl } = useThree()

  const updateVideoProgress = () => {
    setVideoProgress((v) => v + 1)
  }

  const loadImage = (src) => {
    return TL.load(src)
  }

  const loadVideo = (id, src, map, el) => {
    const { loop } = el

    const p = new Promise((res) => {
      const video = document.createElement("video")
      video.style.display = "block"
      video.style.position = "fixed"
      video.style.top = "100%"
      video.style.visibility = "hidden"
      video.crossOrigin = "anonymous"
      video.loop = loop
      video.playsInline = true
      video.preload = "metadata"
      video.src = src
      video.volume = 0
      document.body.appendChild(video)

      // video.load()
      video.onloadedmetadata = () => {
        setTimeout(() => {
          const texture = new VideoTexture(video)

          map.set(id, texture)
          updateVideoProgress()

          res()
        }, 100)
      }
    })

    videoPromises.current.push(p)

    return p
  }

  const loadModel = (id, src, m) => {
    ML.load(src, (res) => {
      m.set(id, res)
    })
  }

  const loadEnvMap = async (id, src, m) => {
    m.set(id, TL.load(src))
    envMapsIDs.current.push(id)
  }

  const createEnvMaps = (m) => {
    const pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()

    envMapsIDs.current.forEach((id) => {
      const envMap = pmremGenerator.fromEquirectangular(m.get(id)).texture
      pmremGenerator.dispose()
      m.set(id, envMap)
    })
  }

  const assets = useMemo(() => {
    // PREVENT SSR ISSUE
    if (typeof window !== "undefined") {
      const m = new Map()

      // STATIC ASSETS
      threeAssetsList.forEach((el) => {
        const { assetType, id, src } = el
        if (assetType === "image") {
          m.set(id, loadImage(src))
        } else if (assetType === "video") {
          loadVideo(id, src, m)
        } else if (assetType === "model") {
          loadModel(id, src, m)
        } else if (assetType === "envMap") {
          loadEnvMap(id, src, m)
        }
      })

      // DYNAMIC CMS ASSSETS
      content.decades.forEach((decade) => {
        decade.chapters.forEach((chapter) => {
          chapter.timelineChapter.content.forEach((el) => {
            const { assetType, asset } = el
            const { id, mediaItemUrl } = asset

            if (assetType === "image") {
              m.set(id, loadImage(mediaItemUrl))
            } else if (assetType === "video") {
              loadVideo(id, mediaItemUrl, m, el)
            }
          })
        })
      })

      return m
    }
  }, [])

  useEffect(() => {
    const threePromise = new Promise((res) => {
      LM.onLoad = () => {
        res()
      }
    })

    LM.onProgress = (path, item, total) => {
      setTimeout(() => {
        setThreeProgress(item / total)
      }, 600)
    }

    Promise.all([...videoPromises.current, threePromise]).then(async () => {
      createEnvMaps(assets)
      await new Promise((res) => setTimeout(res, 500))
      setLoadingProgress(0.95)
      await new Promise((res) => setTimeout(res, 600))
      setLoadingProgress(1)
      await new Promise((res) => setTimeout(res, 200))
      setIsAppLoaded(true)
    })
  }, [])

  useEffect(() => {
    const pVideo = videoProgress / videoPromises.current.length
    const progress = (pVideo + threeProgress) * 0.5 * 0.9
    setLoadingProgress(progress)
  }, [videoProgress, threeProgress])

  return (
    <ThreeAssetsContext.Provider
      value={{
        assets,
        reusableResources,
      }}
    >
      {children}
    </ThreeAssetsContext.Provider>
  )
}
