import { useState, useEffect } from "react"
import isMobile from "ismobilejs"

export default function useIsMobileDevice() {
  const [result, setResult] = useState({})
  useEffect(() => {
    setResult(isMobile(window.navigator))
  }, [])
  return result
}
