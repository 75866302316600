import React, { useRef, useMemo, useState, useEffect } from "react"
import { useFrame, useThree, extend } from "@react-three/fiber"
import { Text } from "troika-three-text"

import { useGlobalContext, useThreeViewport } from "@hooks"

import { config, rafOrder } from "@data"

import font from "../../../../../static/fonts/sharpsansdisplayno1-bold.woff"
import vertex from "./shaders/index.vert"
import fragment from "./shaders/index.frag"

extend({ Text })

export default React.memo(function Plane({ progressID, renderOrder, year }) {
  const text = useRef()
  const { chapters, camera, postprocessing, fog } = config
  const { distanceFadePlanes } = fog
  const { initialDistance } = camera
  const { distanceBetween } = chapters
  const { fisheye } = postprocessing

  const { size } = useThree()
  const { progress, mousePosition } = useGlobalContext()

  const [textSizes, setTextSizes] = useState([1, 1])

  const viewport = useThreeViewport()
  const { width } = viewport

  const scaleX = width / textSizes[0]
  const scaleY = scaleX
  const positionZ = progressID * distanceBetween + initialDistance

  const args = useMemo(
    () => ({
      uniforms: {
        distanceFadeNear: { value: distanceFadePlanes.near },
        distanceFadeFar: { value: distanceFadePlanes.far },
        uSpeed: { value: 0 },
        uResolution: { value: [size.width, size.height] },
        uScale: { value: fisheye.scale },
        uMouse: { value: [0.0] },
        depthTest: false,
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    }),
    [],
  )

  useEffect(() => {
    text.current.addEventListener("synccomplete", () => {
      const bb = text.current.geometry.boundingBox
      const { min, max } = bb
      const w = max.x - min.x
      const h = max.y - min.y

      setTextSizes([w, h])
    })
  }, [])

  useFrame(() => {
    args.uniforms.uSpeed.value = progress.current.speed
    args.uniforms.uMouse.value = [mousePosition.current.x, mousePosition.current.y]
  }, rafOrder.threeContent)

  return (
    <text
      ref={text}
      position-z={-positionZ}
      scale-x={scaleX}
      scale-y={scaleY}
      renderOrder={renderOrder}
      lineHeight={0.8}
      glyphGeometryDetail={30}
      text={year}
      font={font}
      anchorX="center"
      anchorY="middle"
    >
      <shaderMaterial
        args={[args]}
        transparent
        uniforms-uResolution-value={[size.width, size.height]}
      />
    </text>
  )
})
