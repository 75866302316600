import React from "react"

export default React.memo(function DebugViewport({ viewport, renderOrder }) {
  return (
    <>
      <mesh
        scale-x={viewport.width}
        scale-y={viewport.height}
        position-z={-0.01}
        renderOrder={renderOrder - 10}
      >
        <planeBufferGeometry args={[1, 1]} />
        <meshBasicMaterial transparent opacity={0.2} />
      </mesh>
    </>
  )
})
