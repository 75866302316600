import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"

import { useGlobalContext } from "@hooks"

export default React.memo(function FastScrollOverlay() {
  const { needsFastScrollOverlay } = useGlobalContext()

  const controls = useAnimation()

  useEffect(async () => {
    if (needsFastScrollOverlay) {
      await controls.start({
        opacity: 1,
        transition: { duration: 0.65, ease: "easeInOut", delay: 0.45 },
      })
      await controls.start({
        opacity: 0,
        transition: { duration: 0.55, ease: "easeInOut", delay: 1.7 },
      })
    }
  }, [needsFastScrollOverlay])

  return (
    <motion.div
      animate={controls}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "black",
        zIndex: 10,
        opacity: 0,
        pointerEvents: "none",
      }}
    />
  )
})
