import React, { useRef, useEffect, useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"

import {
  useElementPosition,
  useElementDimensions,
  useWindowDimensions,
  useGlobalAction,
  useGlobalContext,
  useT,
} from "@hooks"

import Countdown from "./Countdown"
import VisitTimeline from "./VisitTimeline"
import ARButton from "./ARButton"
import SwitchTrophy from "./SwitchTrophy"

import LogoSVG from "@static/images/webgl/landing/concacaf-logo.svg"

import * as styles from "./EndContent.module.scss"

import QRNation from "@assets/images/Concacaf60_NationsLeagueTrophy_QRCode_1000x1000.png"
import QRGold from "@assets/images/Concacaf60_GoldCupTrophy_QRCode_1000x1000.jpg"
import QRYouthLeague from "@assets/images/Concacaf60_YouthLeagueTrophy_QRCode_1000x1000.png"
import Link from "@components/dom/Link"
import { css } from "@jsUtils"

const data = {
  "gold-cup": {
    qr: QRGold,
    arAssetUrl: "/models/gold-cup.glb",
    iosAssetUrl: "/models/gold-cup.usdz",
  },
  "nations-league": {
    qr: QRNation,
    arAssetUrl: "/models/nations-league.glb",
    iosAssetUrl: "/models/nations-league.usdz",
  },
  "champions-league": {
    qr: QRYouthLeague,
    arAssetUrl: "/models/youth-league.glb",
    iosAssetUrl: "/models/youth-league.usdz",
  },
}

export default function EndContent({ mode }) {
  const mainRef = useRef()
  const { top: elementTop } = useElementPosition(mainRef)
  const { height: elementHeight } = useElementDimensions(mainRef)

  const { currentTrophy } = useGlobalContext()

  const { scrollY } = useViewportScroll()
  const [windowWidth, windowHeight] = useWindowDimensions()
  const [isMounted, setIsMounted] = useState(false)

  const maxScrollPos = isMounted ? document.scrollingElement.scrollHeight - windowHeight : 0
  const maxScrollInEndContent = Math.min(elementTop + elementHeight, maxScrollPos)

  const scrollWithinEndContent = useTransform(scrollY, (y) => y - elementTop)
  const endContentScrollProgress = useTransform(
    scrollWithinEndContent,
    (s) => (s + windowHeight) / (maxScrollInEndContent - elementTop + windowHeight),
  )

  const { setIsEndContentOnTop } = useGlobalAction()
  useEffect(() => scrollWithinEndContent.onChange((y) => setIsEndContentOnTop(y >= -20)))

  const t = useT("timeline")

  const isMobile = windowWidth < windowHeight

  const currentData = data[currentTrophy]
  // console.log(currentData)
  useEffect(() => {
    import("@google/model-viewer")
    setIsMounted(true)
    if (mode !== "timeline") {
      // actions.unlockScroll()
      document.body.classList.add("active")
    }
  }, [])
  return (
    <>
      <main className={styles.container} ref={mainRef} id="end-content">
        <div className={styles.belowContent}>
          <motion.div
            className={styles.backgroundRing}
            style={{ rotateZ: useTransform(scrollWithinEndContent, (s) => s / 5) }}
          >
            <LogoSVG />
          </motion.div>
          {mode !== "timeline" && (
            <>
              <div
                className={css(
                  styles.trophy,
                  styles.nationsLeague,
                  currentTrophy == "nations-league" && styles.active,
                )}
              >
                <model-viewer
                  src="/models/nations-league.glb"
                  ios-src="/models/nations-league.usdz"
                  alt="A 3D model of the CONCACAF Nations’ League trophy"
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  auto-rotate
                  disable-zoom
                  interaction-prompt="none"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div
                className={css(
                  styles.trophy,
                  styles.goldCup,
                  currentTrophy == "gold-cup" && styles.active,
                )}
              >
                <model-viewer
                  src="/models/gold-cup.glb"
                  ios-src="/models/gold-cup.usdz"
                  alt="A 3D model of the CONCACAF Gold Cup trophy"
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  auto-rotate
                  disable-zoom
                  interaction-prompt="none"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div
                className={css(
                  styles.trophy,
                  styles.youthLeague,
                  currentTrophy == "champions-league" && styles.active,
                )}
              >
                <model-viewer
                  src="/models/youth-league.glb"
                  ios-src="/models/youth-league.usdz"
                  alt="A 3D model of the CONCACAF Youth League trophy"
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  auto-rotate
                  disable-zoom
                  interaction-prompt="none"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </>
          )}
          <motion.div
            className={styles.backgroundText}
            style={{
              rotate: isMobile ? 90 : 0,
              x: {
                true: "-50%",
                false: useTransform(endContentScrollProgress, [0, 1], ["0vw", "-100vw"]),
              }[isMobile],
              y: { true: useTransform(scrollWithinEndContent, (s) => -s / 2), false: 0 }[isMobile],
            }}
          >
            2021
          </motion.div>
        </div>

        <div className={styles.aboveContent}>
          <h1>{t("end-content.title")}</h1>
          <p className={styles.subtitle}>{t("end-content.subtitle")}</p>

          <div className={styles.siteNav}>
            <Link to={"/timeline/"}>Timeline</Link>
            {/* <Link to={"/predictive-gaming/"}>Predictive Gaming</Link> */}
            <Link to={"/game/"}>Skills Challenge</Link>
          </div>

          <div className={styles.spacer}></div>

          {/* <Countdown className={styles.countdown} /> */}

          {mode == "timeline" && <VisitTimeline mode={mode} />}
          {/* <Signup /> */}
          <div className={styles.qr}>
            <img src={currentData.qr} />
            <p>{t("end-content.qr")}</p>
          </div>

          <ARButton
            className={styles.arButton}
            assetUrl={currentData.arAssetUrl}
            iosAssetUrl={currentData.iosAssetUrl}
          />
          <SwitchTrophy className={styles.switchTrophy} />
        </div>
      </main>
    </>
  )
}
