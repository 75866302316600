import React, { useContext } from "react"

import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import { SiteContext } from "@context/siteContext"

import Cross from "@dom"
import Link from "@components/dom/Link"
import LanguageSwitch from "../LanguageSwitch"

const Nav = () => {
  const { actions, navOpen } = useContext(SiteContext)

  return (
    <div className={css(Styles.nav, navOpen && Styles.active)}>
      <div className={Styles.siteNav}>
        <Link to={"/timeline/"}>Timeline</Link>
        {/* <Link to={"/predictive-gaming/"}>Predictive Gaming</Link> */}
        <Link to={"/game/"}>Skills Challenge</Link>
        <LanguageSwitch />
      </div>
    </div>
  )
}

export default Nav
