import React, { useState, useEffect } from "react"

import { useMediaQuery } from "@helpers/breakpoints"

import { useThreeViewport, useGlobalContext, useThreeAsset } from "@hooks"

import { Description } from "@webgl"

import { config } from "@data"
import Single from "./Single"
import Split from "./Split"

export default function Image({ data, renderOrder, progressID, internalID }) {
  const { isAppLoaded } = useGlobalContext()
  const { camera, chapters } = config
  const viewport = useThreeViewport(camera.focusDistance)
  const [ratio, setRatio] = useState()

  const hasDescription = !!data?.description

  const { specs, asset } = data
  const { id } = asset

  const { x, y, z, scale } = useMediaQuery({
    xs: specs.sm,
    ...specs,
  })

  const positionX = (x - 0.5) * viewport.width
  const positionY = (y - 0.5) * viewport.height
  const positionZ = (z - 0.5) * chapters.internalDepthSpread
  const scaleX = scale * viewport.width
  const scaleY = scaleX / ratio

  const texture = useThreeAsset(id)

  useEffect(() => {
    if (isAppLoaded) {
      setRatio(texture.image.width / texture.image.height)
    }
  }, [isAppLoaded])

  return (
    <group
      position-x={positionX}
      position-y={positionY}
      position-z={positionZ + 0.01 * internalID}
      matrixAutoUpdate={false}
      onUpdate={(self) => self.updateMatrix()}
    >
      <group
        scale-x={scaleX}
        scale-y={scaleY}
        matrixAutoUpdate={false}
        onUpdate={(self) => self.updateMatrix()}
      >
        <Single renderOrder={renderOrder + 2 + internalID} texture={texture} />
        <Split
          color="#FFD43A"
          renderOrder={renderOrder + 1 + internalID}
          direction={[0.1, 0.1]}
          texture={texture}
          offsetFactor={1}
        />
        <Split
          color="#3AE2FF"
          renderOrder={renderOrder + 0 + internalID}
          direction={[-0.1, -0.1]}
          texture={texture}
          offsetFactor={2}
        />
      </group>

      {hasDescription && (
        <Description
          data={data.description}
          parentDimensions={[scaleX, scaleY, 1]}
          renderOrder={renderOrder}
          progressID={progressID}
        />
      )}
    </group>
  )
}
