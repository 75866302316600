import { useState, useEffect } from "react"

export function useWindowDimensions() {
  const [windowDims, setWindowDims] = useState([0, 0])
  useEffect(() => {
    const update = () => setWindowDims([window.innerWidth, window.innerHeight])
    update()
    window.addEventListener("resize", update)
    return () => window.removeEventListener("resize", update)
  }, [])

  return windowDims
}

// tracks getBoundingClientRect
export function useElementDimensions(elementRef) {
  const [dims, setDims] = useState({ top: 0, left: 0, height: 0, width: 0 })
  // Update dimensions on mount and whenever the size changes
  useEffect(() => {
    if (elementRef.current !== null) {
      const el = elementRef.current
      const {
        width: initialWidth,
        height: initialHeight,
        top: initialTop,
        left: initialLeft,
      } = el.getBoundingClientRect()
      setDims({ width: initialWidth, height: initialHeight, top: initialTop, left: initialLeft })
      const ro = new ResizeObserver(([{ contentRect }]) => {
        const { width, height, top, left } = contentRect
        setDims({ width, height, top, left })
      })
      ro.observe(el)
      return () => ro.unobserve(el)
    }
    return () => {}
  }, [elementRef])

  return dims
}

// Tracks offsetTop / offsetLeft
export function useElementPosition(elementRef) {
  const [dims, setDims] = useState({ top: 0, left: 0 })
  // Update dimensions on mount and whenever the size changes
  useEffect(() => {
    if (elementRef.current !== null) {
      const el = elementRef.current
      const update = () => {
        setDims({ top: el.offsetTop, left: el.offsetLeft })
      }
      update()
      window.addEventListener("resize", update)
      return () => window.addEventListener("resize", update)
    }
    return () => {}
  }, [elementRef])

  return dims
}
