/* eslint-disable no-restricted-properties */
import { useMemo } from "react"
import { CatmullRomCurve3, Vector3, MathUtils } from "three"

import { useGlobalContext, useResponsiveVersion } from "@hooks"

import { config } from "@data"

const V3 = new Vector3()

export default function useCameraPath() {
  const { lastChapterPosition, trophyPosition } = useGlobalContext()
  const { trophy } = config
  const { orbitRadius, rotationAngle, modelHeight } = trophy

  const closeRadius = orbitRadius * 0.5
  const isMobile = useResponsiveVersion("mobile")

  const getCircularPoint = (radius, angle, p) => {
    const initial = Math.pow(MathUtils.smootherstep(p, 0.0, 0.2), 0.35)
    const ending = MathUtils.smootherstep(p, 0.5, 1)
    let r = MathUtils.lerp(closeRadius, orbitRadius, ending)

    r *= 1.0 + (1.0 - initial) * 2.0

    return V3.set(
      0 + r * Math.sin(angle),
      0 - modelHeight * 0.45 * initial + modelHeight * 0.45 * ending,
      trophyPosition.z + r * Math.cos(angle),
    ).clone()
  }

  const getOrbitPath = (radius, angle, steps) => {
    const arr = new Array(steps).fill(null)
    const p = 1 / (steps - 1)

    return arr
      .map((el, i) => {
        return getCircularPoint(radius, i * p * angle, i * p)
      })
      .filter((value) => value !== false)
  }

  const path = useMemo(() => {
    const arr = [
      V3.set(0, 0, 0).clone(),
      V3.set(0, 0, -lastChapterPosition * 0.3).clone(),
      V3.set(0, 0, -lastChapterPosition * 0.6).clone(),
      V3.set(0, 0, -lastChapterPosition * 0.8).clone(),
      V3.set(0, 0, -lastChapterPosition * 0.9).clone(),
      V3.set(0, 0, -lastChapterPosition * 1.0).clone(),
    ]

    if (!isMobile) {
      arr.push(...getOrbitPath(orbitRadius, rotationAngle, 50))
    } else {
      arr.push(V3.set(0, 0, trophyPosition.z + orbitRadius).clone())
    }

    const curve = new CatmullRomCurve3(arr)

    curve.tension = 0.4

    return curve
  }, [isMobile])

  return path
}
