import React, { useContext } from "react"

import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import { SiteContext } from "@context/siteContext"
import Cross from "@dom"

const Menu = () => {
  const { actions, navOpen, background } = useContext(SiteContext)
  const toggleMenu = () => {
    actions.setNavOpen(!navOpen)
  }

  return (
    <div className={css(Styles.wrapper)}>
      <div
        className={css(Styles.component, navOpen && Styles.active, Styles[background])}
        onClick={toggleMenu}
      >
        <div className={Styles.menu}>
          MENU <span></span>
        </div>
      </div>
    </div>
  )
}

export default Menu
