import { useRef, useState } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { MathUtils } from "three"

import { config, rafOrder } from "@data"

/*
  This is based on the distance between the camera and a specific Z point.
  Because is easier than think in scroll position. Refactor / change if necessary
*/

export default function useProgress(z, n, f) {
  const [isActive, setIsActive] = useState(false)
  const progress = useRef(0)

  const { trackingDistanceRange } = config

  const near = n || trackingDistanceRange[0]
  const far = f || trackingDistanceRange[1]

  const { camera } = useThree()

  useFrame(() => {
    let d = camera.position.z - z
    d = MathUtils.clamp(d, near, far)
    d = 1 - MathUtils.smoothstep(d, near, far)

    progress.current = d

    if (isActive && (progress.current > 0.999 || progress.current < 0.001)) {
      setIsActive(false)
    } else if (!isActive && progress.current < 0.999 && progress.current > 0.001) {
      setIsActive(true)
    }
  }, rafOrder.threeContent)

  return [progress, isActive]
}
