// extracted by mini-css-extract-plugin
export var component = "index-module--component--D8j7V";
export var active = "index-module--active--2l9S5";
export var background = "index-module--background--1xqik";
export var container = "index-module--container--8TJDm";
export var content = "index-module--content--26PMW";
export var headline = "index-module--headline--FRxnH";
export var cross = "index-module--cross--3YXaJ";
export var skip = "index-module--skip--3ztxR";
export var desktopSkip = "index-module--desktopSkip--zsGzR";
export var mobileSkip = "index-module--mobileSkip--3UJGe";
export var desktopDates = "index-module--desktopDates--UN3Wi";
export var mobileDates = "index-module--mobileDates--1cHXG";
export var copy = "index-module--copy--3pK7x";
export var filler = "index-module--filler--24haI";
export var video = "index-module--video--Kqb73";
export var play = "index-module--play--Lk_jZ";