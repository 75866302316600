import React, { useEffect, useMemo } from "react"
import { useFrame, useLoader } from "@react-three/fiber"
import { Color, MathUtils } from "three"

import { useGlobalContext } from "@hooks"

import { rafOrder, config } from "@data"

import Shape from "./Shape"

import SVGGeometryLoader from "@jsUtils/svgGeometryLoader.js"

export default React.forwardRef(function Ring({ radius, ...props }, ref) {
  const { colors } = config
  const { progress, loadingProgress } = useGlobalContext()

  const ratio = 35 / 150
  const widthGeometry = 0.35
  const amount = 40

  const geometry = useLoader(SVGGeometryLoader, {
    src: "/images/webgl/landing/diamond.svg",
    size: [widthGeometry, widthGeometry / ratio],
  })

  const white = useMemo(() => {
    return new Color("white")
  }, [])

  const gold = useMemo(() => {
    return new Color(colors.gold)
  }, [])

  const color = useMemo(() => {
    return white.clone()
  }, [])

  const nodes = useMemo(() => {
    return new Array(amount).fill(0).map((el, i) => {
      const angle = -(i / amount) * Math.PI * 2
      const progressID = (i + 1) / amount
      const step = 1 / amount

      return (
        <Shape
          key={i}
          angle={angle}
          geometry={geometry}
          radius={radius}
          progressID={progressID}
          step={step}
          color={color}
        />
      )
    })
  }, [loadingProgress, radius])

  useFrame(() => {
    // ROTATE
    if (progress.current.smoothValue < window.innerHeight * 1.4) {
      ref.current.rotation.z = -(progress.current.smoothValue / window.innerHeight) * 1.5
    }

    // CHANGE COLOR
    if (progress.current.smoothValue < window.innerHeight * 0.1) {
      color.lerpColors(
        white,
        gold,
        MathUtils.smoothstep(progress.current.smoothValue, 0, window.innerHeight * 0.1),
      )
    }
  }, rafOrder.threeContent)

  return (
    <group ref={ref} {...props}>
      {nodes}
    </group>
  )
})
