import { useContext, useMemo } from "react"
import { ThreeAssetsContext } from "@context/threeAssets"

import get from "lodash-es/get"

export default function useReusableResource(key) {
  const { reusableResources } = useContext(ThreeAssetsContext)

  return useMemo(() => get(reusableResources, key, reusableResources), [key])
}
