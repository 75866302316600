import React, { useEffect, useState, useContext, useRef, useCallback } from "react"
import { css } from "@jsUtils"
import * as styles from "./index.module.scss"

import { SiteContext } from "@context/siteContext"
import LogoSVG from "@static/images/webgl/landing/concacaf-logo.svg"

const Transition = () => {
  const { transition, ready, actions } = useContext(SiteContext)
  const transitionRef = useRef()
  const [mounted, setMounted] = useState(false)

  const handleTransition = useCallback(() => {
    transitionRef.current.removeEventListener("transitionend", handleTransition)
    if (transition && !ready) {
      setTimeout(() => {
        actions.loadRoute()
      }, 666)
    }
    if (transition && ready) {
      actions.setTransition(false)
      setTimeout(() => {
        actions.setReady(false)
      }, 666)
    }
    if (!transition && ready) {
      setTimeout(() => {
        actions.setReady(false)
      }, 666)
    }
  }, [ready, transition])

  useEffect(() => {
    if (transition) transitionRef.current.addEventListener("transitionend", handleTransition)
  }, [transition])

  useEffect(() => {
    if (ready) setMounted(true)
    if (ready) transitionRef.current.addEventListener("transitionend", handleTransition)
  }, [ready])

  return (
    <div
      className={css(
        styles.transition,
        !mounted && !ready && styles.firstLoad,
        transition && styles.active,
        ready && styles.out,
      )}
    >
      <div ref={transitionRef} className={styles.transitionWipe}></div>
      <div className={styles.loader}>
        <LogoSVG />
        <div className={styles.text}>LOADING</div>
      </div>
    </div>
  )
}

export default Transition
