import React from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"

export default function Cross({ theme, position, className }) {
  return (
    <div
      className={css(
        Styles.cross,
        theme && Styles[theme],
        className,
        position.indexOf("top") > -1 && Styles.top,
        position.indexOf("left") > -1 && Styles.left,
        position.indexOf("bottom") > -1 && Styles.bottom,
        position.indexOf("right") > -1 && Styles.right,
      )}
    ></div>
  )
}
