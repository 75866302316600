import React, { useState, useEffect } from "react"
import { useMobileDevice, useT } from "@hooks"

import { css } from "@jsUtils"
import * as styles from "./ARButton.module.scss"

export default function ARButton({ className, assetUrl, iosAssetUrl }) {
  const [iOSCapable, setiOSCapable] = useState(false) // we have a real test for iOS devices
  const androidCapable = !!useMobileDevice()?.android?.device // treat all android devices as capable

  useEffect(() => {
    setiOSCapable(!!document.createElement("a").relList?.supports?.("ar"))
  }, [])

  const hide = !iOSCapable && !androidCapable

  const t = useT("timeline")

  return (
    <div className={className}>
      <button
        type="button"
        className={css(styles.base, hide && styles.hidden)}
        onClick={(e) => {
          e.preventDefault()

          // imperatively trigger AR view from click on a single button element (the same element on
          // either platform)

          if (iOSCapable) {
            // based on https://github.com/google/model-viewer/blob/e2f6eb6b53df3bc21ce1694089b7cbd6aee1389c/packages/model-viewer/src/features/ar.ts#L365-L395
            const modelUrl = new URL(iosAssetUrl, window.location.href)
            const anchor = document.createElement("a")
            anchor.setAttribute("rel", "ar")
            const img = document.createElement("img")
            anchor.appendChild(img)
            anchor.setAttribute("href", modelUrl.toString())
            anchor.click()
          } else if (androidCapable) {
            const anchor = document.createElement("a")
            anchor.setAttribute(
              "href",
              `intent://arvr.google.com/scene-viewer/1.0?file=${
                new URL(assetUrl, window.location.href).href
              }&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${
                window.location.href
              };end;`,
            )
            anchor.click()
          }
        }}
      />
      <div className={styles.text}>{t("end-content.ar")}</div>
    </div>
  )
}
