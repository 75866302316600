import React, { useMemo } from "react"
import { Color } from "three"

import { useFrame } from "@react-three/fiber"

import { useGlobalContext, useReusableResource, useResponsiveVersion } from "@hooks"

import { rafOrder, config } from "@data"

import vertex from "../../shaders/splitPlane/index.vert"
import fragment from "../../shaders/splitPlane/index.frag"

export default function Plane({ color, renderOrder, direction, texture, offsetFactor }) {
  const { progress } = useGlobalContext()
  const { fog, chapters } = config
  const geometry = useReusableResource("geometries.plane")
  const version = useResponsiveVersion("mobile") ? "mobile" : "desktop"

  const { distanceFadePlanes } = fog
  const { nearClipFadeRange } = chapters

  const args = useMemo(
    () => ({
      uniforms: {
        distanceFadeNear: { value: distanceFadePlanes.near },
        distanceFadeFar: { value: distanceFadePlanes.far },
        uNearClipFadeRange: { value: nearClipFadeRange[version] },
        uTxt: { value: texture },
        uSpeed: { value: 0 },
        uColor: { value: new Color(color) },
        uDirection: { value: direction },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      depthTest: false,
    }),
    [],
  )

  useFrame(() => {
    args.uniforms.uSpeed.value = progress.current.speed
  }, rafOrder.threeContent)

  return (
    <mesh
      renderOrder={renderOrder}
      matrixAutoUpdate={false}
      onUpdate={(self) => {
        self.updateMatrix()
      }}
      geometry={geometry}
    >
      <shaderMaterial
        args={[args]}
        uniforms-uTxt-value={texture}
        transparent
        polygonOffset
        polygonOffsetFactor={40 * offsetFactor}
        polygonOffsetUnits={10000 * offsetFactor}
        uniforms-uNearClipFadeRange-value={nearClipFadeRange[version]}
      />
    </mesh>
  )
}
