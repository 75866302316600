import React, { useEffect } from "react"

import { useThreeViewport, useProgress } from "@hooks"
import { useMediaQuery } from "@helpers/breakpoints"

import { Description } from "@webgl"

import { config } from "@data"
import Single from "./Single"
import Split from "./Split"

import useVideoTexture from "./useVideoTexture"

export default function Plane({
  data,
  renderOrder,
  isChapterActive,
  progress,
  chapterPositionZ,
  progressID,
  internalID,
}) {
  const { camera, chapters } = config
  const viewport = useThreeViewport(camera.focusDistance)
  const useChromaKey = data?.keyOutBackground || data?.key_out_background || false

  const { specs, asset } = data
  const { id } = asset

  const hasDescription = !!data?.description

  const { x, y, z, scale } = useMediaQuery({
    xs: specs.sm,
    ...specs,
  })

  const [, isPlayActive] = useProgress(chapterPositionZ, -15, 25)
  const [texture, ratio] = useVideoTexture(id, isChapterActive, progress, isPlayActive)

  const positionX = (x - 0.5) * viewport.width
  const positionY = (y - 0.5) * viewport.height
  const positionZ = (z - 0.5) * chapters.internalDepthSpread
  const scaleX = scale * viewport.width
  const scaleY = scaleX / ratio

  return (
    <group
      position-x={positionX}
      position-y={positionY}
      position-z={positionZ + 0.01 * internalID}
      matrixAutoUpdate={false}
      onUpdate={(self) => self.updateMatrix()}
    >
      <group
        scale-x={scaleX}
        scale-y={scaleY}
        matrixAutoUpdate={false}
        onUpdate={(self) => self.updateMatrix()}
      >
        <Single
          texture={texture}
          renderOrder={renderOrder + 2 + internalID}
          useChromaKey={useChromaKey}
        />
        <Split
          texture={texture}
          color="#FFD43A"
          renderOrder={renderOrder + 1 + internalID}
          direction={[0.1, 0.1]}
          useChromaKey={useChromaKey}
          offsetFactor={1}
        />
        <Split
          texture={texture}
          color="#3AE2FF"
          renderOrder={renderOrder + 0 + internalID}
          direction={[-0.1, -0.1]}
          useChromaKey={useChromaKey}
          offsetFactor={2}
        />
      </group>

      {hasDescription && (
        <Description
          data={data.description}
          parentDimensions={[scaleX, scaleY, 1]}
          renderOrder={renderOrder}
          progressID={progressID}
        />
      )}
    </group>
  )
}
