import React, { useRef, useState, useEffect } from "react"

import * as Styles from "./index.module.scss"
import { Cross } from "@dom"

import { css } from "@jsUtils"

const VideoOverlay = () => {
  const [open, setOpen] = useState(true)
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef()

  const playVideo = () => {
    videoRef.current.play()
    setPlaying(true)
  }

  useEffect(() => {
    if (!open) videoRef.current.pause()
  }, [open])

  return (
    <div className={css(open && Styles.active, Styles.component)}>
      <div className={Styles.background} />
      <div className={Styles.container}>
        <div className={Styles.content}>
          <div className={Styles.headline}>
            <Cross className={Styles.cross} theme={"white"} position={"top left"} />
            <Cross className={Styles.cross} theme={"white"} position={"top right"} />
            <Cross className={Styles.cross} theme={"white"} position={"bottom left"} />
            <Cross className={Styles.cross} theme={"white"} position={"bottom right"} />

            <h1>Celebrate 60 years of Concacaf with us.</h1>
            <p className={Styles.desktopDates}>
              <span className={Styles.copy}>September 18, 1961</span>
              <span className={Styles.filler} />
              <span className={Styles.copy}>September 18, 2021</span>
            </p>
            <div className={css(Styles.skip, Styles.desktopSkip)} onClick={() => setOpen(false)}>
              Click to see 60 years of Concacaf
            </div>
          </div>
          <div className={Styles.video} onClick={playVideo}>
            <video
              onEnded={() => setOpen(false)}
              ref={videoRef}
              src="/videos/manifesto_eng_v5_wcaptions.mp4"
            />
            {!playing && <div className={Styles.play}></div>}
          </div>
          <p className={Styles.mobileDates}>
            <span className={Styles.copy}>September 18, 1961</span>
            <span className={Styles.filler} />
            <span className={Styles.copy}>September 18, 2021</span>
          </p>
          <div className={css(Styles.skip, Styles.mobileSkip)} onClick={() => setOpen(false)}>
            Click to see 60 years of Concacaf
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoOverlay
