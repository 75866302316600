import React, { useEffect, useRef, useState, useMemo } from "react"
import { gsap } from "gsap"

import { useThreeAsset } from "@hooks"

import { config } from "@data"

export default React.memo(function Trophy({ isActive, assetKey, meshName, shadowScale }) {
  const { scene } = useThreeAsset(assetKey)
  const tMesh = useMemo(() => scene.getObjectByName(meshName), [])
  const env = useThreeAsset("trophy-envmap")
  const shadow = useThreeAsset("trophy-shadow")
  const trophyRef = useRef()
  const groupRef = useRef()
  const shadowRef = useRef()

  const renderOrder = isActive ? 0 : 10

  const [scale, setScale] = useState(200)

  const isFirstRender = useRef(true)

  const { trophy } = config
  const { modelHeight } = trophy

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      tMesh.geometry.computeBoundingBox()
      const { y } = tMesh.geometry.boundingBox.max

      setScale(modelHeight / y)
    }

    scene.traverse((el) => {
      if (el.isMesh) {
        el.material.envMap = env
        el.material.transparent = true
        el.material.needsUpdate = true
      }
    }, true)
  }, [])

  useEffect(() => {
    let tl = gsap.timeline({
      delay: isActive ? 0.38 : 0,
    })

    const duration = 0.6

    if (isActive) {
      let dum = { opacity: 0 }
      tl.to(groupRef.current.scale, {
        duration: duration * 1.4,
        x: 1,
        y: 1,
        z: 1,
        ease: "Power4.easeOut",
      })
        .to(groupRef.current.rotation, { duration, y: `-=${Math.PI}`, ease: "Power1.easeOut" }, 0)
        .to(
          dum,
          {
            duration,
            opacity: 1,
            ease: "Power1.easeOut",
            onUpdate: () => {
              tMesh.material.opacity = dum.opacity
              shadowRef.current.material.opacity = dum.opacity
            },
          },
          0,
        )
    } else {
      let dum = { opacity: 1 }

      tl.to(groupRef.current.scale, { duration, x: 0, y: 0, z: 0, ease: "Power1.easeIn" })
        .to(groupRef.current.rotation, { duration, y: `-=${Math.PI}`, ease: "Power1.easeIn" }, 0)
        .to(
          dum,
          {
            duration,
            opacity: 0,
            ease: "Power1.easeIn",
            onUpdate: () => {
              tMesh.material.opacity = dum.opacity
              shadowRef.current.material.opacity = dum.opacity
            },
          },
          0,
        )
    }
  }, [isActive])

  return (
    <group ref={groupRef} renderOrder={renderOrder}>
      <primitive ref={trophyRef} object={scene} name="trophy-model" scale={[scale, scale, scale]}>
        <mesh
          ref={shadowRef}
          position-y={-0.001}
          rotation-x={-Math.PI * 0.5}
          scale={[shadowScale, shadowScale, shadowScale]}
          name="trophy-shadow"
          matrixAutoUpdate={false}
          onUpdate={(self) => self.updateMatrix()}
        >
          <planeBufferGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial map={shadow} transparent />
        </mesh>
      </primitive>
    </group>
  )
})
