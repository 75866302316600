import React from "react"
import { css } from "@jsUtils"

import * as styles from "./index.module.scss"

export default React.memo(function Header() {
  return (
    <>
      {/* <div className={styles.whiteBit} /> */}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src="/images/concacaf-logo.svg" />
          </div>
          <div className={css(styles.socials)}>
            <div className={styles.smallTitle}>Follow us</div>
            <div>
              <a href="https://www.instagram.com/concacaf/?hl=en" target="_blank">
                Instagram
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/concacafcom" target="_blank">
                Facebook
              </a>
            </div>
            <div>
              <a href="https://twitter.com/CONCACAF" target="_blank">
                Twitter
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/user/concacaf/videos" target="_blank">
                Youtube
              </a>
            </div>
          </div>
          <div className={css(styles.info)}>
            <div className={styles.smallTitle}>Info</div>
            <div>
              <a href="https://www.concacaf.com/terms-conditions/">Terms & Conditions</a>
            </div>
            <div>
              <a href="https://www.concacaf.com/privacy-policy/">Privacy Policy </a>
            </div>
            <div>
              <a href="https://www.concacaf.com/contact-us/">Contact us </a>
            </div>
          </div>
          <div className={styles.copy}>&copy; 2021 CONCACAF. All rights reserved.</div>
        </div>
      </div>
    </>
  )
})
