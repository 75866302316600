// extracted by mini-css-extract-plugin
export var root = "index-module--root--3pfoZ";
export var isLeft = "index-module--isLeft--SUTeo";
export var isMobile = "index-module--isMobile--1NveO";
export var cross = "index-module--cross--38ESN";
export var isTopLeft = "index-module--isTopLeft--2u7Im";
export var isTopRight = "index-module--isTopRight--2llH4";
export var isBottomLeft = "index-module--isBottomLeft--358Af";
export var isBottomRight = "index-module--isBottomRight--DwkhP";
export var content = "index-module--content--2k1GF";
export var contentTop = "index-module--contentTop--3m9aA";
export var contentTopYear = "index-module--contentTopYear--1gHtG";
export var contentTopIcon = "index-module--contentTopIcon--PdYX-";
export var contentBottom = "index-module--contentBottom--2_c2N";