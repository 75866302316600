import React, { useMemo, useEffect, useState } from "react"
import { useT } from "@hooks"

import { css } from "@jsUtils"
import * as styles from "./Countdown.module.scss"

function timeDelta(target) {
  const secondMs = 1000
  const minuteMs = secondMs * 60
  const hourMs = minuteMs * 60
  const dayMs = hourMs * 24

  let ms = target - new Date()
  const days = Math.floor(ms / dayMs)
  ms -= days * dayMs
  const hours = Math.floor(ms / hourMs)
  ms -= hours * hourMs
  const minutes = Math.floor(ms / minuteMs)
  ms -= minutes * minuteMs
  const seconds = Math.floor(ms / secondMs)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

if (typeof window !== "undefined") window.timeDelta = timeDelta

export default function Countdown({ className }) {
  const t = useT("timeline")
  const targetDate = useMemo(() => new Date("2021-07-10T17:00:00-04:00"), [])
  let { days, hours, minutes, seconds } = timeDelta(targetDate)
  let setDays, setHours, setMinutes, setSeconds
  ;[days, setDays] = useState(days)
  ;[hours, setHours] = useState(hours)
  ;[minutes, setMinutes] = useState(minutes)
  ;[seconds, setSeconds] = useState(seconds)

  useEffect(() => {
    const int = setInterval(() => {
      const {
        days: newDays,
        hours: newHours,
        minutes: newMinutes,
        seconds: newSeconds,
      } = timeDelta(targetDate)
      setDays(newDays)
      setHours(newHours)
      setMinutes(newMinutes)
      setSeconds(newSeconds)
      return
    }, 250)
    return () => clearInterval(int)
  })

  return (
    <div className={css(styles.base, className)}>
      <span className={styles.label}>{t("end-content.kick-in")}</span>
      <span className={styles.count}>
        <span data-label="D">{days.toString().padStart(2, "0")}</span>{" "}
        <span data-label="H">{hours.toString().padStart(2, "0")}</span>:
        <span data-label="M">{minutes.toString().padStart(2, "0")}</span>:
        <span data-label="S">{seconds.toString().padStart(2, "0")}</span>
      </span>
    </div>
  )
}
