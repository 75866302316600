// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-game-js": () => import("./../../../src/pages/game.js" /* webpackChunkName: "component---src-pages-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-predictive-gaming-index-js": () => import("./../../../src/pages/predictive-gaming/index.js" /* webpackChunkName: "component---src-pages-predictive-gaming-index-js" */),
  "component---src-pages-predictive-gaming-leaderboard-js": () => import("./../../../src/pages/predictive-gaming/leaderboard.js" /* webpackChunkName: "component---src-pages-predictive-gaming-leaderboard-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-trophies-gold-cup-js": () => import("./../../../src/pages/trophies/gold-cup.js" /* webpackChunkName: "component---src-pages-trophies-gold-cup-js" */),
  "component---src-pages-trophies-nations-league-js": () => import("./../../../src/pages/trophies/nations-league.js" /* webpackChunkName: "component---src-pages-trophies-nations-league-js" */),
  "component---src-pages-trophies-youth-league-js": () => import("./../../../src/pages/trophies/youth-league.js" /* webpackChunkName: "component---src-pages-trophies-youth-league-js" */)
}

