import React, { useMemo } from "react"

import { useGlobalContext } from "@hooks"

import { config } from "@data"

import { Chapter, Years } from "@webgl"

export default React.memo(function Decades({ data }) {
  const { content } = useGlobalContext()
  const { chapters, camera } = config
  const { initialDistance } = camera
  const { distanceBetween } = chapters

  const { itemsAmount } = content
  const renderFactor = 10
  const yearsRenderOrder = itemsAmount - data.progressID

  const chapterNodes = useMemo(() => {
    return data.chapters.map((el, i) => {
      return (
        <Chapter
          key={i}
          data={el.timelineChapter.content}
          positionZ={-(el.progressID * distanceBetween + initialDistance)}
          renderOrder={(itemsAmount - el.progressID) * renderFactor}
          progressID={el.progressID}
        />
      )
    })
  }, [])

  return (
    <group>
      <Years
        year={data.year}
        progressID={data.progressID}
        renderOrder={yearsRenderOrder * renderFactor}
      />
      {chapterNodes}
    </group>
  )
})
