import React, { useRef } from "react"
import { motion } from "framer-motion"

import { useGlobalContext, useGlobalAction } from "@hooks"

import { css } from "@jsUtils"
import * as styles from "./index.module.scss"

export default React.memo(function SwitchTrophy({ className }) {
  const canUserClick = useRef(true)
  const { currentTrophy } = useGlobalContext()
  const { setCurrentTrophy } = useGlobalAction()
  const options = ["gold-cup", "nations-league", "champions-league"]
  const currentIndex = useRef(0)

  const prev = () => {
    changeTrophy(-1)
  }

  const next = () => {
    changeTrophy(1)
  }

  const changeTrophy = (dir) => {
    if (!canUserClick.current) return

    canUserClick.current = false
    currentIndex.current = currentIndex.current + dir
    if (currentIndex.current === options.length) {
      currentIndex.current = 0
    } else if (currentIndex.current < 0) {
      currentIndex.current = options.length - 1
    }
    setCurrentTrophy(options[currentIndex.current])
    setTimeout(() => (canUserClick.current = true), 1500)
  }

  const variants = {
    hide: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    },
  }

  return (
    <div className={css(styles.root, className)}>
      <div className={styles.arrow} onClick={prev}>
        <img src={"/images/icons/arrow--slider.svg"} />
      </div>
      <div className={styles.labels}>
        <motion.div variants={variants} animate={currentTrophy === "gold-cup" ? "show" : "hide"}>
          Gold Cup
        </motion.div>
        <motion.div
          variants={variants}
          animate={currentTrophy === "nations-league" ? "show" : "hide"}
        >
          NATIONS LEAGUE
        </motion.div>
        <motion.div
          variants={variants}
          animate={currentTrophy === "champions-league" ? "show" : "hide"}
        >
          CHAMPIONS LEAGUE
        </motion.div>
      </div>
      <div className={styles.arrow} onClick={next}>
        <img src={"/images/icons/arrow--slider.svg"} />
      </div>
    </div>
  )
})
