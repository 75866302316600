import React, { useRef } from "react"
import { useGlobalContext } from "@hooks"
import { useFrame, useLoader } from "@react-three/fiber"
import * as THREE from "three"

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix *
                  modelViewMatrix *
                  vec4(position,1.0);
  }
`
const fragmentShader = `
  uniform sampler2D textTexture;
  uniform vec2 mousePos;
  varying vec2 vUv;

  // adapted from https://www.iquilezles.org/www/articles/functions/functions.htm
  float gain(float x, float k) {
    float x01 = (x + 1.) / 2.;
    float a = 0.5 * pow(2.0 * ((x01<0.5)?x01:1.0-x01), k);
    float out01 = (x01<0.5)?a:1.0-a;
    return out01 * 2. - 1.;
  }

  // https://en.wikipedia.org/wiki/Alpha_compositing
  vec4 aOverB(vec4 a, vec4 b) {
    float alpha0 = a.a + b.a * (1. - a.a);
    vec3 color0 = (a.rgb * a.a + b.rgb * b.a * (1. - a.a)) / alpha0;
    vec4 rgba = vec4(color0.rgb, alpha0);
    return clamp(rgba, vec4(0.), vec4(1.));
  }

  void main() {
    vec4 textMask = texture2D(textTexture, vUv);
    float text = dot(textMask.rgb, vec3(1.)) / 3.;

    // Create shimmer on text
    const vec4 gradColorA = vec4(0.35, 0.30, 0.20, 1.);
    const vec4 gradColorB = vec4(0.82, 0.68, 0.42, 1.);
    const float slope = 3.;
    const float scale = 0.25;
    float anglePos = vUv.x - slope * vUv.y;
    anglePos += (mousePos.x - mousePos.y) * 0.18;
    vec4 textColor = mix(gradColorA, gradColorB, sin(anglePos / scale) * 0.5 + 0.5);

    // Create split offsets
    vec2 offset = vec2(gain(mousePos.x, 3.), gain(mousePos.y, 3.)) * 0.030;
    const float NUM_LAYERS = 10.;
    // “Positive” split (towards the mouse)
    float positiveSplitBlurred = 0.0;
    for (float i=0.; i<NUM_LAYERS; i++) {
      positiveSplitBlurred += clamp(dot(texture2D(textTexture, vUv - offset * (i / NUM_LAYERS)).rgb, vec3(1)) / 3. / NUM_LAYERS, 0., 1. / NUM_LAYERS);
    }
    vec4 positiveSplitColor = vec4(
      mix(vec3(1.0, 0.9, 0.0), vec3(1.0, 0.0, 0.0), smoothstep(0.9, 0.2, positiveSplitBlurred)),
      positiveSplitBlurred
    );
    // “Negative” split (away from the mouse)
    float negativeSplitBlurred = 0.0;
    for (float i=0.; i<NUM_LAYERS; i++) {
      negativeSplitBlurred += clamp(dot(texture2D(textTexture, vUv + offset * (i / NUM_LAYERS)).rgb, vec3(1)) / 3. / NUM_LAYERS, 0., 1. / NUM_LAYERS);
    }
    vec4 negativeSplitColor = vec4(
      mix(vec3(0.0, 0.9, 1.0), vec3(0.0, 0.0, 1.0), smoothstep(0.9, 0.2, negativeSplitBlurred)),
      negativeSplitBlurred
    );

    // All together
    gl_FragColor =
      text * textColor +
      (1. - text) * aOverB(positiveSplitColor, negativeSplitColor);
  }
`

const shader = {
  vertexShader,
  fragmentShader,
  uniforms: {
    textTexture: { value: null },
    mousePos: { value: [0, 0] },
  },
}

export default React.forwardRef(function Sixty(props, ref) {
  const texture = useLoader(THREE.TextureLoader, "/images/webgl/landing/60.jpg")

  // Update mouse position in shader
  const { mousePosition } = useGlobalContext()
  const shaderMaterialRef = useRef()
  useFrame(() => {
    const { x, y } = mousePosition.current
    shaderMaterialRef.current.uniforms.mousePos.value = [x, y]
    shaderMaterialRef.current.uniforms.mousePos.needsUpdate = true
  })

  return (
    <mesh {...props} ref={ref}>
      <planeBufferGeometry args={[2.2, 2.2]} />
      <shaderMaterial
        args={[shader]}
        uniforms-textTexture-value={texture}
        ref={shaderMaterialRef}
        transparent
      />
    </mesh>
  )
})
