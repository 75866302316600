/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"

export default function useContextBridge(...contexts) {
  const cRef = React.useRef([])
  cRef.current = contexts.map((context) => React.useContext(context))
  return React.useMemo(
    () =>
      ({ children }) =>
        contexts.reduceRight(
          (acc, Context, i) =>
            React.createElement(Context.Provider, { value: cRef.current[i], children: acc }),
          children,
        ),
    [],
  )
}
